
import React, { Component } from 'react';
import $ from 'jquery';
import { Grid } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import ApiUrlCall from './Api/apiurl';
import '../main.css'
import { Link , Redirect } from 'react-router-dom';
import matchpointlogo from '../assets/images/matchpointlogo.png';
import logout from '../assets/images/logout.png';
import Common_function from './Common_function';
import { osName,osVersion,browserName,deviceType } from 'react-device-detect';
import host from './ipaddress';
import { sha256 } from 'js-sha256';

class Api_integration extends Component {
  _exporter_vehicleList
  constructor(props){
    super(props);
    this.state = {
        checkedA : false,
        text : 'Api Integration Disabled',
        emailAddress : '',
        openAlert : false,
        errorMessage : '',
        keyData : '',
       showReport : false,
         // showReport:true,
        ReportContent : '',
        vehicleListData : '',
        user1 : localStorage.getItem("username"),
        userGroups: localStorage.getItem('user_groups'),
        apiList : '',
        documentData : '',
        host : host,
    };
    this.sendEmail = this.sendEmail.bind(this);
  }

  componentDidMount(){
      this.getApiState()
     this.getApiDoc()    
  }

  export_vehicle_list_report = () => {
    // console.log('22222222222')
    this._exporter_vehicleList.save();
  }
  getApiDoc = () => {
      ApiUrlCall.getApiDocument().then(response => {
        // console.log('response document', response)
        if(response.data.response_code === 200 && response.data.is_success){
            this.setState({
                documentData : response.data.data
            })
        }else{
            this.setState({
                openAlert : true,
                errorMessage : response.data.response_message
            })
        }
      }).catch(error => {
          console.log('error while fetching api documents', error)
      })
  }

logoutHandler =(e) =>{
  var user_id = localStorage.getItem("user")
  var sha256 = require('js-sha256');
  var userid_enc = sha256(user_id);

  var active_session = {
    start_time : Common_function.ServerDateTime(localStorage.getItem("cache focus date")),
    end_time: Common_function.ServerDateTime(new Date())
  }
   console.log(active_session ,"logout time detail")
  var device_detail = [{
   event_id : 1,
  unique_id : userid_enc,
    os_version : osVersion,
    model_name: osName,
    value : active_session,user_id:user_id,
  
  }]
  
  const analytics_details = {
    data : device_detail , source : 2
  };

  
 JSON.stringify(analytics_details)

//  console.log("hello..", analytics_details)
        
  ApiUrlCall.send_analytics(analytics_details).then(response => {
     
    if (response.status === 200) {
      return

    }
  }
  )
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("mobile_number");
  localStorage.setItem('cache focus date','')


  setTimeout(function(){
    window.location.reload()
  }, 1000);  
}




  getApiState = () => {
      $.ajax({
        url: this.state.host+'/v1/mpgps/tractorjunctionDashboard/pub_api/status/',
        dataType: 'json',
        type:'post',
        data:{},
        beforeSend: function(request) {
            request.setRequestHeader("authorization", localStorage.getItem("token"));
            request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number" )}));
            $("#loading-image").show();
            $(".loading-icon").show();
          },
        success: function(data) {
     //  console.log("show data..",data)
       if(data.response_code === 200 && data.is_success){
        var message
        if(data.data.is_enabled){
            message = "Api Integration Enabled"
        }else{
            message = "Api Integration Disabled"
        }
        this.setState({
         
          checkedA : data.data.is_enabled,
          text : message,
          keyData : data.data,
        }, () => {
          $("#loading-image").hide()
          $(".loading-icon").hide()
        })
        }
        }.bind(this),
        error: function(xhr, status, err) {
       //   console.error(this.props.url, status, err.toString());
        //  console.log('fail');
       //   alert("Internal Server Error");
          $("#loading-image").hide();
          $(".loading-icon").hide();
          if(err.toString() == "Forbidden"){
           //this.logoutHandler()
          }
        }.bind(this)
      });

  }



  setApiState = (value) => {                 //  toggle   function
    const data1 = { toggle_api: value };
  
    $.ajax({
      url: this.state.host + '/v1/mpgps/tractorjunctionDashboard/pub_api/toggle/',
      dataType: 'json',
      type: 'post',
      data: JSON.stringify(data1), // Send data1 as the request payload
      contentType: 'application/json', // Set the content type to JSON
      beforeSend: function (request) {
        request.setRequestHeader("authorization", localStorage.getItem("token"));
        request.setRequestHeader('api-head', JSON.stringify({ "user_id": localStorage.getItem("user") }));
        $("#loading-image").show();
        $(".loading-icon").show();
      },
      success: (data) => {
    //    console.log("toggle data..", data)
        if (data.response_code === 200 && data.is_success) {
          this.getApiState();
        } else {
          this.setState({
            // openAlert: true,
          });
        }
      },
      error: (error) => {
        console.log('error while setting api state', error);
      },
    });
  }

  
  downloadFile = (link,formate,name) => {
    //   console.log('download method is called')
    axios({
        url: link,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name+'.'+formate);
        document.body.appendChild(link);
        link.click();
      }); 
  }

validateEmail = (emailField) => {
  const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const inputValue = document.getElementById(emailField).value;
  
  //  console.log('Input value:', inputValue);
  return reg.test(inputValue); // Use test method to check if it matches the regular expression
};


sendEmail = () => {
  const { emailAddress } = this.state;
  const validEmail = this.validateEmail('email_id'); // Validate the email address

  if (emailAddress) {
    if (validEmail) {
      const data = { email: emailAddress };

      // Log the data being sent to the backend
    //  console.log('Data being sent to the backend:', data);

      $.ajax({
        type: 'POST',
        url: `${this.state.host}/v1/mpgps/tractorjunctionDashboard/pub_api/email_report/`,
        contentType: 'application/json',
        data: JSON.stringify(data),
        beforeSend: function (request) {
          // Set headers here
          request.setRequestHeader('authorization', localStorage.getItem('token'));
          request.setRequestHeader('api-head', JSON.stringify({ user_id: localStorage.getItem('user') }));

          $('#loading-image').show();
          $('.loading-icon').show();
        },
        success: (response) => {
         // console.log('Response from the backend:', response);
          if (response.response_code === 200) {


          //  console.log('print email',emailAddress)
          //  console.log(this.state.emailAddress) 
            $('#email_id').val('');
            this.setState({
              emailAddress: '', // Clear the email address in the state
              openAlert: true,
              errorMessage: 'Email send successfully.',
            });
          }
        },

        error: (error) => {
       //   console.log('Error while sending email', error);
          this.setState({
            openAlert: true,
            errorMessage: 'Error sending email. Please try again later.',
          });
        },
      });
    } else {
      this.setState({
        openAlert: true,
        errorMessage: 'Invalid Email',
      });
    }
  } else {
    this.setState({
      openAlert: true,
      errorMessage: 'Enter Email Address',
    });
  }
};

  

  toggle = (event) => {
    //   console.log('i am toggling')
      $('.api_parent').scrollTop()
      var textval
      if (event.target.checked) {
        this.setApiState(true)
      }else{
        $('#warning_message').slideDown(600)        
      }
      this.setState({ ...this.state, 
        [event.target.name]: event.target.checked, 
        text : textval,
    });
  }

  disabledYes = () => {
    $('#warning_message').fadeOut(200)
    this.setApiState(false)
  }

  disabledNo = () => {
      $('#warning_message').fadeOut(200)
      this.getApiState()
  }

  viewReport = () => {
      this.setState({
          // ReportContent : <ApiReport />,
          showReport : true,
      })
  }

  regenerateApiKey = () => {
      $('#regenerate_api').slideDown("slow")
  }

  generateYes = () => {
    $.ajax({ 
      url: this.state.host + '/v1/mpgps/tractorjunctionDashboard/pub_api/regenerate/',
      method: 'POST', // Use the appropriate HTTP method (e.g., POST, GET, PUT, etc.)
      dataType: 'json',// Specify the expected data type of the response
      beforeSend: function (request) {
        request.setRequestHeader("authorization", localStorage.getItem("token"));
        request.setRequestHeader('api-head', JSON.stringify({ "user_id": localStorage.getItem("user") }));
        $("#loading-image").show();
        $(".loading-icon").show();
      },
      success: (response) => {
      //   console.log('regenratee responce ', response)
         //this.getApiState()
       //  $('#regenerate_api').fadeOut('slow');
        if (response.response_code === 200  ) {
          $('#regenerate_api').fadeOut('slow');
           this.getApiState()
        } else {
          this.setState({
            openAlert: true,
            errorMessage: "Api key gerate success",
            keyData : response.data,

          });
        }
      },
      error: (error) => {
      //  console.log('error while re-generating API key', error);
      },
    });
  };
  



  
  generateNo = () => {
      $('#regenerate_api').fadeOut('slow')
  }

  viewApiVehicleList = () => {
    $('#api_vehicle_list').slideDown('slow')
  }
  modalClose = () => {
    $('#api_vehicle_list').fadeOut("slow")    
}

copyToClipboard = (element) => {        
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove();
    this.setState({
        openAlert : true,
      errorMessage : "Text Copied to Clipboard",
    })
}


  render(){
    const {t} = this.props
    const {keyData, vehicleListData,} = this.state
    // console.log('checked status is', this.state.checkedA)
  return (
    <div className="maincontener">
      {/* header component start */} 
       
      <div className="navbar navbar-default">
        <div className="container-fluid pl-0">
          <div className="navbar-header">
            <a className="header-logo">
              <Link to="/lenderdashboard">
                <img
                  src={matchpointlogo}
                  alt="matchpointlogo"
                  width="300"
                  className="logo"
                />
              </Link>
            </a>
          </div>
          <div className="nav navbar-nav navbar-right">
            <div className="list-inline mb-0">
              <div
                id="profile_menu"
                className="list-inline-item fs-21 fc-red text-capitalize border-right cursor-pointer usernamemenu"
                onMouseOver={(e) => {
                  //console.log('Focused on input');
                  //$("#profile_menu").toggle()
                }}
                onMouseLeave={(e) => {
                  //console.log('Focused Out input');
                  //$("#profile_menu").toggle()
                }}
              >
                Welcome, {this.state.user1}
                <span className="color-orange"></span>
               

                <ul>
          
          {this.state.userGroups[0] === '5' && this.state.userGroups[2] === '7' ? (
            <li>
               <Link to="/Api_integration" style={{ color: 'black', textDecoration: 'none',  }} className="custom-link" >
                 
                 <span style={{ textDecoration: 'none' }} >  Api Integration </span>

               </Link>
          </li> ) : null}
        
        </ul> 
              </div>
  
              {/* <div className="list-inline-item">
                <a onClick={e => this.logoutHandler(e)} className="cursor-pointer">
                  <img src={logout} alt="logout-logo" width="30" />
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* header component end */}
      
      <Grid className='backbtn' >
       <Link to="/home">
      
          <img src="\assets\images\arrow.png"></img>
  
        <p style={{display :"inline",marginLeft:"10px",color:'black'}} 
       
        > Back </p>
       

       
       </Link> 
     
      </Grid>
         
       
      <div className="api_parent">
          <Grid xs={12}>
             <p className="API_header"  >
                API INTEGRATION DETAILS
              </p>
          </Grid>
             
        <div className="user_profile">
          <Grid container>
            <Grid item xs={12}>
              <Switch
                checked={this.state.checkedA}
                onChange={this.toggle}
                name="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              <p className="cj_w_header" style={{ display: 'inline' }}>
                {this.state.text}
              </p>

              <p className="help_text">Turn ON will enable API Integration</p>
            </Grid>
            <Grid item xs={12} >
              <p
                className="cj_w_header"
                style={{
                  display: this.state.checkedA ? 'inline' : 'none',
                  float: 'right',
                  marginTop: '8px',
                }}
              >

                <b style={{ color: '#F0762B',fontSize: '20px'}}>API Key </b> :-  
                <span id="api_key">{keyData.api_key}</span>
              </p>
            </Grid>
          </Grid>

          
        </div>
        <div
          id="email_div"
          style={{
            marginTop: '25px',
            marginBottom: '15px',
            pointerEvents: this.state.checkedA ? 'auto' : 'none',
            opacity: this.state.checkedA ? '1' : '0.4',
          }}
        >
          <Grid container>
            <Grid item xs={8}>
              <img
                src="images/arrow.png"
                alt="icon"
                style={{
                  marginRight: '10px',
                  display: this.state.showReport ? '' : 'none',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  this.setState({
                    showReport: false,
                    ReportContent: '',
                  });
                }}
              ></img>
              
            </Grid>
  
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <div  className="addtional_detail_input">
                  
                  <input
                    type="text"
                    placeholder="Please enter email address"
                    id="email_id"
                    className="addtional_detail_input"
                    style={{ width: '100%' }}
                    onChange={(e) => {
                      this.setState({
                        emailAddress: e.currentTarget.value,
                      });
                    }}
                    autoComplete="on"
                  ></input>


                  </div>
                  <div>
                  <p className="help_text"> Enter email address to share API details</p>
                  <button
                    className="btn btn-block orange-btn"
                    style={{ marginTop: '10px', padding: '8px 10px', }}
                    onClick={this.sendEmail}
                  > send </button>
                  </div>
                </Grid>
                
              </Grid>
             
            </Grid>
          </Grid>
        </div>
        <div
          id="api_content_div"
          style={{
            display: this.state.showReport ? 'none' : '',
            pointerEvents: this.state.checkedA ? 'auto' : 'none',
            opacity: this.state.checkedA ? '1' : '0.4',
          }}
        >
         

    <Grid container spacing={5} style={{ marginTop: '20px' }}>
  <Grid item xl={3} xs={4}>
    <Grid container spacing={2} className="user_profile" style={{ padding: '5px', marginLeft: '50px' }}>
      <Grid item xl={2} xs={6}>
        <img className="api_image" style={{ width: '100%' }} src="\assets\images\user_profile_img.png" alt="icon" />
      </Grid>
      <Grid item xl={10} xs={6}>
        <p className="p_header">API INTEGRATION - USER GUID</p>
        <Grid container>
          <Grid item xs={6}  className="clickable-text">
          
            <a href={this.state.documentData.doc_url} target="_blank" className="href_style"> <p>View</p> </a>
          </Grid>

          <Grid item xs={6} className="clickable-text">
           

             <a href={this.state.documentData.doc_url} target="_blank" className="href_style"> <p> Download </p> </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  



  <Grid item xl={3} xs={4}>
    <Grid container spacing={2} className="user_profile" style={{ padding: '5px', marginLeft: '50px' }}>
      <Grid item xl={2} xs={6}>
        <img className="api_image" style={{ width: '100%' }} src="\assets\images\setting_api.png" alt="icon" />
      </Grid>
      <Grid item xl={10} xs={6}>
        <Grid container>
          <Grid item xs>
            <p className="p_header">API KEY</p>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} className="clickable-text">
            <p
              onClick={() => {
                this.copyToClipboard("#api_key");
              }}
            >
              Copy
            </p>
          </Grid>
          <Grid item xs={6} className="clickable-text" onClick={this.regenerateApiKey}>
            <p>Regenerate</p>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
</Grid> 

        </div>
        <div style={{ display: this.state.showReport ? '' : 'none' }}>
          {this.state.ReportContent}
        </div>
        {/* warning message modal */}
        <div id="warning_message" className="modal_parent" style={{ marginLeft: '-100px' }}>
          <div className="modal_body renew_modal_body" style={{ width: '60%', textAlign: 'center', lineHeight: '2em' }}>
            <p className="p_header">Diabling API Integration will disable all your services that are available through this API Integration<br /><br />Do you still want to proceed ?</p>
            <Grid container>
              <Grid item xs={6}>
                <button onClick={this.disabledYes} className="btn orange-btn" style={{ float: 'none', width: '30%' }}>yes</button>
              </Grid>
              <Grid item xs={6}>
                <button onClick={this.disabledNo} className="btn orange-btn" style={{ float: 'none', width: '30%' }}>no</button>
              </Grid>
            </Grid>
          </div>
        </div>
  
        {/* regenerate API key warning */}
        <div id="regenerate_api" className="modal_parent" style={{ marginLeft: '-100px' }}>
          <div className="modal_body renew_modal_body" style={{ width: '60%', textAlign: 'center', lineHeight: '2em' }}>
            <p className="p_header">Regenerating API Key will change the old API Key & service activated via old API Key <br /><br />Do you still want to proceed</p>
            <Grid container>
              <Grid item xs={6}>
                <button onClick={this.generateYes} className="btn orange-btn" style={{ float: 'none', width: '30%' }}>Yes</button>
              </Grid>
              <Grid item xs={6}>
                <button onClick={this.generateNo} className="btn orange-btn" style={{ float: 'none', width: '30%' }}>NO</button>
              </Grid>
            </Grid>
          </div>
        </div>
  
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={4000}
          onClose= {(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    </div>
  );
  
  }
}

export default Api_integration ;
