import React, { Component } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css"
import { FaMapMarker } from 'react-icons/fa';
import { FaSearch } from 'react-icons/fa';
import { FaShare } from 'react-icons/fa';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import Icon from '../icon';
import {Form, Input, FormGroup, Container, Label} from 'reactstrap';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import Modal from 'react-responsive-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import ReactExport from "react-export-excel";
import moment from 'moment';
import { Link , Redirect } from 'react-router-dom';

import matchpointlogo from '../assets/images/matchpointlogo.png';
import logout from '../assets/images/logout.png';
import orange from '../assets/images/orange.png';
import '../main.css'
import { render } from "react-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import Dialog from 'react-dialog'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { CSVLink, CSVDownload } from "react-csv";
import Background from '../assets/images/New_Project.png';
import ReactTooltip from "react-tooltip";
import "react-table/react-table.css";
import axios from "axios";
import Flag from "react-flags";
import "antd/dist/antd.css";
import { Table, Tooltip } from "antd";
import host from './ipaddress';
import Common_function from './Common_function';
import { osName,osVersion,browserName,deviceType } from 'react-device-detect';
import ApiUrlCall from './Api/apiurl';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var gl_requestcode;
var gl_requesttype;
var gl_orderno;
var gl_posts = [];
var gl_list = [];
var gl_req_data = []
var timercounter = 0;
var fnl_req_code;
var fnl_req_type;
var gl_filtervalue;
var gl_filtervalue1;
var today_date = new Date();
var tomorrow = new Date(today_date.getFullYear(), today_date.getMonth(), (today_date.getDate() + 1));
class Order_status extends Component{
  constructor(props){
    super(props);

    const token = localStorage.getItem("token")

        let loggedIn = true
        if(token == null){
          loggedIn = false
        }
      this.state={
        loggedIn,

        historydata:[],
        common_message:'',
        value: '',
        date: null,
        focused: null,
        open: false,
        weekperiod:'',
        monthperiod:'',
        range:'',
        textareaValue: '',
        favoritecolor: "red",
        userGroups: localStorage.getItem('user_groups'),

        //  host:'http://localhost:8000',
        //host:'http://157.245.106.11:8000',
        //host:'https://lenderdbbackend01.do-blr.mpgpsdc.com',
        host :host,
        filtered: [],
        filterAll: "",
        // filtervalue:'',
        totalcount:'',
        totaldata:'',
        option : 0,
        
        isDialogOpen: false,
        is_reactajax_called : false,
        startDate: moment().subtract(2,"day"),
        endDate: moment(),
        focusedInput: null,

        sendEndTime: "23:59",
        sendStartTime: "00:00",
        tableData : [],
      }
      this.handleClick2 = this.handleClick2.bind(this);
      this.renderEditable = this.renderEditable.bind(this);
      this.updateCellAjax = this.updateCellAjax.bind(this);
      this.setIdDiv = this.setIdDiv.bind(this);
      this.onChange = this.onChange.bind(this);
    
      this.showPassword = this.showPassword.bind(this);
      this.postshareLocation = this.postshareLocation.bind(this);
      

  }
  // state = {
  //   startDate: moment().subtract(2, "day"),
  //   endDate: moment(),
  //   focusedInput: null
  // };
  handleDateChange = ({ startDate, endDate }) =>
    this.setState({ startDate, endDate });
  handleFocusChange = focusedInput => this.setState({ focusedInput });



  openDialog = () => this.setState({ isDialogOpen: true })

  handleClose = () => this.setState({ isDialogOpen: false })

  onRowClick = (state, rowInfo, instance) => {
    return {
      onClick: (e, handleOriginal) => {
        if (handleOriginal) {
          handleOriginal();
        }
        $(this).remove();
      }
    };
  };

  gettotalcount(responsedata){
    //console.log("gettotalcount",responsedata.length)
    return responsedata.length;
  }

  ////////////////////////////////////////////////////////////

  createServerDateTime = (date, time) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if(month <= 9){
      month = '0'+month
    }
    if(day <= 9){
      day = '0'+day
    }
    var datetime = year + "-" + month + "-" + day + " " + time + ":" + "00"
    return datetime
  }

  getDashboardData = (submitflag = false) =>{
    const {endDate,startDate,sendEndTime,sendStartTime} = this.state
  
    // fnl_req_code = gl_requestcode;
    // fnl_req_type = gl_requesttype
    var start_date_time = this.createServerDateTime(startDate._d,sendStartTime)
    var end_date_time = this.createServerDateTime(endDate._d,sendEndTime)
    var data1 = JSON.stringify({'req_type':'all','start_date':start_date_time,'end_date':end_date_time})
    // console.log(data1)

    $.ajax({
      url: this.state.host+'/v1/mpgps/tractorjunctionDashboard/order_status/',
      dataType: 'json',
      type:'post',
      data:data1,
      beforeSend: function(request) {
          request.setRequestHeader("authorization", localStorage.getItem("token"));
          request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number")}));
          $("#loading-image").show();
          $(".loading-icon").show();
        },
      success: function(data) {
        // console.log('success',data.data);
        this.setState({
          tableData : data.data
        },() =>{
          if(this.state.tableData.length == 0){
              alert("No data found.")
          }
        })
        //console.log("@@@@@@@@@@@@@@@@@@@",gl_posts)
      var  totaldata = data.data.length
      //console.log("^^^^^^^^^^^^^",totaldata)
        // this.homeaddress();
        $("#loading-image").hide();
        $(".loading-icon").hide();
        //console.log("aaa",data)
        gl_posts = data.data;
        this.setState({
            totalcount:data,

          });
        if(data.is_success == true){
            if(data.length == 0){
               alert("Data Not Found")
            }
        }else{
            if(data.response_code == 500){
                alert("Internal server Error")
            }else if(data.response_code == 401){
                 alert("Unauthorised user")
            }else{
                alert(data.response_message)
            }
        }
      }.bind(this),
      error: function(xhr, status, err) {
        //console.error(this.props.url, status, err.toString());
        //console.log('fail');
        alert("Internal Server Error");
        $("#loading-image").hide();
        $(".loading-icon").hide();
        if(err.toString() == "Forbidden"){
         //this.logoutHandler()
        }
      }.bind(this)
    });
  }

  ///////////////////////////////////////////////////////////////////////

  componentDidMount(){
    $('#month').hide();
    $('#all').hide()
    this.getDashboardData(true);
    var user1 = localStorage.getItem("username")
    this.setState({ user1 });
    //console.log("getusername",user1);
  }

    handleClick2(value) {
   this.props.history.push('/map?id=true', {"data":value});
   
 }

  onOpenModal = () => {
      this.setState({ open: true });
    };

    save_remarks(serial_number,remark,on_date){
      //console.log(remark, serial_number);
      $.ajax({
        url: this.state.host+'/v1/mpgps/tractorjunctionDashboard/update_remarks/',
        dataType: 'json',
        type:'post',
        data:JSON.stringify({"serial_number":serial_number, "remark":remark,"on_date":on_date }),
        beforeSend: function(request) {
          request.setRequestHeader("authorization", localStorage.getItem("token"));
          request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number")}));
        },
        success: function(data) {
            //console.log("REMARK SAVED");
            if(data.length == 0){
              alert("Data Not Found")
           }
        },
        error: function(err){
            //console.log("ERROR SAING REMARK")
            // alert("Internal Server Error")
        }
    });

      };
   chunkArray(myArray, chunk_size){

        var index = 0;
        var arrayLength = myArray.length;
        var tempArray = [];

        for (index = 0; index < arrayLength; index += chunk_size) {
           var myChunk = myArray.slice(index, index+chunk_size);
            // Do something if you want with the group
            tempArray.push(myChunk);
        }

        return tempArray;

      }
set_data_for_ajax(serial_no,index,col_id,is_ajaxcalled)
{
  //console.log("is_ajaxcalled ",is_ajaxcalled, serial_no)
  var check_ajaxcalled = gl_posts[index].ajax_updated
  // console.log("gl_posts.length ",gl_posts.length , 'check_ajaxcalled ',check_ajaxcalled , serial_no , 'is_ajaxcalled',is_ajaxcalled , 'gl_req_data.length ' ,gl_req_data.length , 'index',index)
  if(check_ajaxcalled == false)
  {
    //debugger

    let check_index = gl_req_data[index]
    if (typeof check_index !== "undefined")
    {
    let check_ajax = gl_req_data[index].is_ajaxcalled
    if (check_ajax==false)
    {

    gl_req_data.push({'serial_no':serial_no,'index':index,'col_id':col_id,'is_ajaxcalled':true})
    //console.log("befor ajax called ",index+2," is ",gl_posts.length)
    if (gl_posts.length == index+2)
    {
      //console.log(gl_req_data)
      // one row take 83 second to add in datatable eg. 5000 take 1 minute to load
      let ajaxtime = gl_req_data.length * 40
      if (ajaxtime > 120000)
      { ajaxtime =  120000}
      setTimeout(() => {
      // new_get_current_address(gl_req_data,0,gl_req_data.length)
      },ajaxtime);


    }
    else{
      //console.log(gl_list.length , index)
      // localStorage.setItem('gl_listlength',gl_posts.length)
      //         localStorage.setItem('index',index)
    }
    return <div id={"upid_"+index+"_"+col_id}>Loading .</div>
  }
  //else { return <div id={"upid_"+index+"_"+col_id}>Loading ..</div> }
}
else {
  gl_req_data.push({'serial_no':serial_no,'index':index,'col_id':col_id,'is_ajaxcalled':true})
  if (gl_posts.length == index+2)
    {
      //console.log(gl_req_data)
      // one row take 83 second to add in datatable eg. 5000 take 1 minute to load
      let ajaxtime = gl_req_data.length * 40
      if (ajaxtime > 120000)
      { ajaxtime =  120000}
      setTimeout(() => {
      // new_get_current_address(gl_req_data,0,gl_req_data.length)
      },ajaxtime);


    }
  return <div id={"upid_"+index+"_"+col_id}>Loading ...</div> }
  }
  else{
    return gl_posts[index][col_id];
  }

}
    onCloseModal = () => {
      this.setState({ open: false });
    };

//dashboard data table
submitHandler = (e) => {
//   const url = "https://jsonplaceholder.typicode.com/posts";
//    fetch(url,{
//      method:"GET"
//    }).then(response => response.json()).then(posts => {
//      console.log("posts",posts)
//      this.setState({
//        posts:posts,

//      })
//      console.log('###########################')
//   })
    const buttonValue = e.target.value
    //console.log("requesttype",this.state.requesttype , "requestcode",this.state.requestcode)
    //console.log("gl_requesttype",gl_requesttype , "gl_requestcode",gl_requestcode)
    

      this.getDashboardData();
  
    
    e.stopPropagation();
    // console.log("zz >> ",this.state)
    // console.log("%%%%%%%%%%%%%%%",this.state.startDate);
    //let sdate = moment(this.state.startDate).format('YYYY-MM-DD');
    //let edate = moment(this.state.endDate).format('YYYY-MM-DD');
    //console.log("%%%%%%%%%%%%%%%",sdate)
    //console.log("%%%%%%%%%%%%%%%",edate)

}

showPassword = (e) => {
  //console.log(e)
  //var x = document.getElementById("new_password");
  var inputs = document.getElementsByClassName('mpassword');
  Array.prototype.forEach.call(inputs, function(x) {
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  })
//}
  
}


searchHandler = (e) => {
  //console.log("$$$$$$$$$$$$$$$$$$",gl_filtervalue)

  if(gl_filtervalue != ""){
  //console.log(123)
    let data2 = JSON.stringify({'order_no':gl_filtervalue,'vehicle_reg':""})

  $.ajax({
    url: this.state.host+'/v1/mpgps/tractorjunctionDashboard/global_search/',
    dataType: 'json',
    cache: false,
    method:'post',
    data:data2,
    beforeSend: function(request) {
      request.setRequestHeader("authorization", localStorage.getItem("token"));
      request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number")}));
      $("#loading-image").show();
      $(".loading-icon").show();
    },
    success: function(data) {
      //console.log(345)
      $("#loading-image").hide();
      $(".loading-icon").hide();
      //console.log('success',data.data);
      if(data.is_success == true){
        gl_posts = data.data
        gl_req_data = []
        this.setState({
          is_reactajax_called: !this.state.is_reactajax_called
        });
      }else{
          if(data.response_code == 500){
              alert("Internal server Error")
          }else if(data.response_code == 401){
               alert("Unauthorised user")
          }else{
              alert(data.response_message)
          }
      }
    }.bind(this),
    error: function(xhr, status, err) {
      //console.error(this.props.url, status, err.toString());
      //console.log('fail');
      // alert("Internal Server Error");
      $("#loading-image").hide();
    }.bind(this)
  });
}
}
searchpressHandler = (e) =>{
  // alert('Working')
  if(e.key  === 'Enter'){
    gl_filtervalue = e.target.value;
      //console.log("####################################",gl_filtervalue)
        this.searchHandler();
  }
}

/////////////////////////////////////////////////////////////////////////////////

searchHandler1 = (e) => {
  // console.log("$$$$$$$$$$$$$$$$$$",gl_filtervalue1)
  if(gl_filtervalue1 != ""){
  //console.log(123)
    let data2 = JSON.stringify({'vehicle_reg':gl_filtervalue1,'order_no': ""})
    //console.log(data2,"llllllllllllll")

  $.ajax({
    url: this.state.host+'/v1/mpgps/tractorjunctionDashboard/global_search/',
    dataType: 'json',
    cache: false,
    method:'post',
    data:data2,
    beforeSend: function(request) {
      request.setRequestHeader("authorization", localStorage.getItem("token"));
      request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number")}));
      $("#loading-image").show();
      $(".loading-icon").show();
    },
    success: function(data) {
      //console.log(345)
      $("#loading-image").hide();
      $(".loading-icon").hide();
      //console.log('success',data.data);
      if(data.is_success == true){
        gl_posts = data.data
        gl_req_data = []
        this.setState({
          is_reactajax_called: !this.state.is_reactajax_called
        });
      }else{
          if(data.response_code == 500){
              alert("Internal server Error")
          }else if(data.response_code == 401){
               alert("Unauthorised user")
          }else{
              alert(data.response_message)
          }
      }
    }.bind(this),
    error: function(xhr, status, err) {
      //console.error(this.props.url, status, err.toString());
      //console.log('fail');
      alert("Internal Server Error");
      $("#loading-image").hide();
    }.bind(this)
  });
}
}

searchpressHandler1 = (e) =>{
  //alert('Working')
  if(e.key  === 'Enter'){
    gl_filtervalue1 = e.target.value;
      // console.log("####################################",gl_filtervalue)
        this.searchHandler1();
  }
}

handlesearchdata1 = (e) => {
  // console.log("******filtervalue",e.target.value)
  gl_filtervalue1 = e.target.value;
    this.setState({gl_filtervalue1: e.target.value})
  
    this.searchHandler1()
     e.stopPropagation()
  }


////////////////////////////////////////////////////////////////////
clickMe(event, tracker, vname ){    
    //  window.open('/map?id=true&data='+tracker+"&vname="+vname, '_blank');
    $("#shareon_trackerno").val(tracker);
    this.postliveLocation()
  }
  postliveLocation(e) {
    // e.preventDefault()
    //console.log('inside postliveLocation method')
    var inputs = document.getElementsByClassName('clsshare_location');
      let reqdata = {}
      let shareon_tracker_number = "";
      let vname = "";
      //let excludelist = ["vname","trackerno"]
      Array.prototype.forEach.call(inputs, function(x) {
        //console.log(x)
        //console.log(x.value)      
        // if (x.value.toString().trim()!="")
        // {
          if (x.name =="trackerno")
          {
            shareon_tracker_number = x.value
          }
          else if (x.name =="vname")
          {
            vname = x.value
          }
          else{
            reqdata[x.name] = x.value
          }
        //}
        
      })
      //console.log(reqdata)
      let entervalues = Object.keys(reqdata).length
      if (entervalues != 0)
      {
        reqdata['serial_number']=shareon_tracker_number
        reqdata['vname']=vname
        $("#loading-image").show();
        $(".loading-icon").show();
        let formdata = reqdata
        //console.log(formdata,"formdata")
        const forgeturl = this.state.host+'/v1/mpgps/tractorjunctionDashboard/share_location/'
        axios.post(forgeturl, formdata, {
          headers: {
            'Authorization': localStorage.getItem('token'),
          },
        })
          .then(response => {
            $("#loading-image").hide();
            $(".loading-icon").hide();
            var link = response.data.share_link;
            //console.log('link ',link)
            window.open(link)
           })
          .catch(error => {
            //console.log(error)  
            alert(error)
          })
        }
        else{
              console.log('error')
        }
    
  }

  clickshareLoaction(event, tracker, vname ){
    $("#shareon_trackerno").val(tracker);
    $("#shareon_vname").val(vname);
    $("#shareon_emailid").val("");
    $("#shareon_mobileno").val("");
    // window.$("#mdsharelocation").modal('show')
    this.postshareLocation();

    //window.$('#Alert-Modal').modal('show')
  }

logoutHandler =(e) =>{
  //console.log("*****")
  var user_id = localStorage.getItem("user")
  var sha256 = require('js-sha256');
  var userid_enc = sha256(user_id);

  var active_session = {
    start_time : Common_function.ServerDateTime(localStorage.getItem("cache focus date")),
    end_time: Common_function.ServerDateTime(new Date())
  }
   console.log(active_session ,"logout time detail")
  var device_detail = [{
   event_id : 1,
  unique_id : userid_enc,
    os_version : osVersion,
    model_name: osName,
    value : active_session,user_id:user_id,
  
  }]
  
  const analytics_details = {
    data : device_detail , source : 2
  };

  
 JSON.stringify(analytics_details)

//  console.log("hello..", analytics_details)
        
  ApiUrlCall.send_analytics(analytics_details).then(response => {
     
    if (response.status === 200) {
      return

    }
  }
  )
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("mobile_number");
  localStorage.setItem('cache focus date','')

  gl_requestcode="";
  gl_requesttype="";
  gl_orderno="";
  gl_posts = [];
  gl_list = [];
  gl_req_data = []
  timercounter = 0;
  // this.props.history.push('/');
  setTimeout(function(){
    window.location.reload()
  }, 1000);  
}

// handledata = (e) => {
//   this.setState({requesttype: e.target.value})
//   gl_requesttype = e.target.value;
//   this.setState({gl_requesttype: e.target.value})
//   console.log("******"+gl_requesttype);
//   if(gl_requesttype == "all"){
//     $('#all').show()
//   }
// }

handlesearchdata = (e) => {
gl_filtervalue = e.target.value;
  this.setState({gl_filtervalue: e.target.value})

  this.searchHandler()
   e.stopPropagation()
}




Print = (e) => {
window.print();
}


deleteRow(id){
  const index = gl_posts.findIndex(post =>{
    return post.id === id
  })
  let copyPosts = [...gl_posts]
     copyPosts.splice(index,1)

   this.setState({posts:copyPosts})
}
buttonClick(){
    var id = $(this).attr('id');
    console.log(id)

}
himohan(this_obj){
  //console.log("this",this_obj)
  //console.log("this",this_obj.original.serial_no)
  var sr_no = this_obj.original.serial_no;
}

handleChange(e) {
  //console.log("$$$$$$$$$$$$$$$$$$$$",e)
  this.setState({textareaValue: e.target.value});
}


setIdDiv(cellInfo){

  if (cellInfo.original.ajax_updated==false)
  {
    let z = <div id={cellInfo.column.id+"_"+cellInfo.index}>Loading ...</div>;
    return z;
  }
  else{
    return gl_posts[cellInfo.index][cellInfo.column.id];
  }
}
updateCellAjax(cellInfo){
  //console.log("updateCellAjax called");
  return(
    this.current_address(cellInfo.original.serial_no,cellInfo.index,cellInfo.column.id,cellInfo.original.ajax_updated)
  )
}
renderEditable(cellInfo) {
  return (
    <div className="textarea-field"
      contentEditable
      suppressContentEditableWarning
      onBlur={e => {
        //console.log("event ",e.target.value, "target",e.target)
        const data = [...gl_posts];
        var remark_srno = data[cellInfo.index].serial_no
        //console.log('remark_srno',remark_srno)
        data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
        var remark_new_value = e.target.innerHTML;
        $("#react-tabs-0").focus()
        // this.setState({ data });
        //console.log("mac",data)
        var on_date = data[cellInfo.index].rpt_on_date
        this.save_remarks(remark_srno,remark_new_value,on_date)
      }}
      dangerouslySetInnerHTML={{
        __html: gl_posts[cellInfo.index][cellInfo.column.id]
      }}
    />
  );
}
onChange(e) {

  if (["new_password","confirm_password"].includes(e.target.id))
  {
    if (document.getElementById('new_password').value ==
              document.getElementById('confirm_password').value) {
          document.getElementById('resetpass').disabled = false;
          document.getElementById('lbl_err').innerHTML = "Matched";
          document.getElementById('lbl_err').style.color = 'green';
        } else {          
          document.getElementById('lbl_err').innerHTML = "Not Matched"
          document.getElementById('lbl_err').style.color = 'red';
          document.getElementById('resetpass').disabled = true;
      }
  }
}


postshareLocation(e) {
  // e.preventDefault()
  //console.log('inside postshareLocation method')
  var inputs = document.getElementsByClassName('clsshare_location');
    let reqdata = {}
    let shareon_tracker_number = "";
    let vname = "";
    //let excludelist = ["vname","trackerno"]
    Array.prototype.forEach.call(inputs, function(x) {
      //console.log(x)
      //console.log(x.value)      
      // if (x.value.toString().trim()!="")
      // {
        if (x.name =="trackerno")
        {
          shareon_tracker_number = x.value
        }
        else if (x.name =="vname")
        {
          vname = x.value
        }
        else{
          reqdata[x.name] = x.value
        }
      //}
      
    })
    //console.log(reqdata)
    let entervalues = Object.keys(reqdata).length
    if (entervalues != 0)
    {
      reqdata['serial_number']=shareon_tracker_number
      reqdata['vname']=vname
      $("#loading-image").show();
      $(".loading-icon").show();
      let formdata = reqdata
      //console.log(formdata,"formdata")
      const forgeturl = this.state.host+'/v1/mpgps/tractorjunctionDashboard/share_location_new/'
      axios.post(forgeturl, formdata, {
        headers: {
          'Authorization': localStorage.getItem('token'),
        },
      })
        .then(response => {
          $("#loading-image").hide();
          $(".loading-icon").hide();
          window.$("#mdsharelocation").modal('show')
          if (response.data.is_success) {
            //document.getElementById('resetpass').disabled = true;        
            document.getElementById('lbl_err_shareloc').innerHTML = response.data.data.message;
            document.getElementById('lbl_err_shareloc').style.color = 'green';            
            window.$("#mdsharelocation").modal('show')

          } else {
            document.getElementById('lbl_err_shareloc').innerHTML = response.data.response_message;
            document.getElementById('lbl_err_shareloc').style.color = 'blue';
          }
        })
        .catch(error => {
          console.log(error)  
          $("#loading-image").hide();
          $(".loading-icon").hide();      
          if (document.getElementById('lbl_err_shareloc').innerHTML !== null) {
            document.getElementById('lbl_err_shareloc').innerHTML = error;
            document.getElementById('lbl_err_shareloc').style.color = 'red';
          }
          // this.setState({
          //   err_msg: error
          // })
        })
      }
      else{
        if (document.getElementById('lbl_err_shareloc').innerHTML !== null) {
          document.getElementById('lbl_err_shareloc').innerHTML = "Please enter mobile number or email id";
          document.getElementById('lbl_err_shareloc').style.color = 'red';
        }
      }
  
}

// selectoption = (e) =>{
//   console.log(e.target.value)
//   this.setState({
//       option : e.target.value
//   })
// }

    render(){
      if(this.state.loggedIn === false){
          return <Redirect to="/" />
        }
        //console.log(this.state,"<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<")

      const { data } =  gl_posts ;
      const { open } = this.state;
      const { weekperiod, monthperiod } = this.state
      // const { filtervalue }= this.state

      const headers = [
        { label: "Sr No.", key: "row_sr_no" },
        { label: "Order No.", key: "los_no" },
        { label: "Order Entry Date and Time.", key: "order_date" },
        { label: "Vehicle Registration No.", key: "vehicle_reg" },
        { label: "Customer Name", key: "customer_name" },
        { label: "Mobile Number", key: "cust_phone_no" },
        // { label: "Home Location", key: "home_location" },
        // { label: "Tracker Status", key: "tracker_status" },
        // { label: "Last Active", key: "last_active" },
       
      ];



      const columns=[
    {
      Header: <Tooltip title="Sr No.">Sr No.</Tooltip>,
      accessor: "row_sr_no",
      style:{
        textAlign:"center"
      },
      Cell: (row) => { return <div>{row.index+1}</div>},
      width:100,
      minWidth:100,
      maxWidth:100
    },
    {
      Header: <Tooltip title="Order No.">Los No.</Tooltip>,
      accessor: "los_no",
      style:{
        textAlign:"center"
      },
      Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>},
      width:100,
      minWidth:100,
      maxWidth:100,
    },
    {
      Header: <Tooltip title="Vehicle Registration No.">Booking Date</Tooltip>,
      accessor: "order_date",
      style:{
        textAlign:"center"
      },
      Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>},
      width:100,
      minWidth:100,
      maxWidth:100,
    },
    {
      Header: <Tooltip title="Vehicle Registration No.">Vehicle Registration No.</Tooltip>,
      accessor: "vehicle_reg",
      style:{
        textAlign:"center"
      },
      Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>},
      width:100,
      minWidth:100,
      maxWidth:100,
    },
    {
      Header: <Tooltip title="Customer Name">Customer Name</Tooltip>,
      accessor: "customer_name",
      sortable:true,
      style:{
        textAlign:"center",
        // 'whiteSpace': 'unset'
      },
      Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>}
    },
    {
      Header: <Tooltip title="Mobile Number">Mobile Number</Tooltip>,
      accessor: "cust_phone_no",
      sortable:true,
      style:{
        textAlign:"center",
        // 'whiteSpace': 'unset'
      },
      Cell: row => <div><span title={row.value}>{row.value}</span></div>
    },
    {
      Header: <Tooltip title="Gps Installation Status">Gps Installation Status</Tooltip>,
      accessor: "installation_status",
      sortable:true,
      style:{
        textAlign:"center",
        // 'whiteSpace': 'unset'
      },
      Cell: row => <div><span title={row.value}>{row.value}</span></div>
    },    
    {
      Header: <Tooltip title="Installation Date">Gps Installation Date</Tooltip>,
      accessor: "installation_date",
      sortable:true,
      style:{
        textAlign:"center",
        // 'whiteSpace': 'unset'
      },
      Cell: row => <div><span title={row.value}>{row.value}</span></div>
    },
    // {
    //   Header: <Tooltip title="Gps no. / Tracker Number">Gps / Tracker Number</Tooltip>,
    //   accessor: "tracker_no",
    //   sortable:true,
    //   style:{
    //     textAlign:"center",
    //     // 'whiteSpace': 'unset'
    //   },
    //   Cell: row => <div><span title={row.value}>{row.value}</span></div>
    // },    
    // {
    //   Header: <Tooltip title="Home Location">Home Location</Tooltip>,
    //   accessor: "home_location",
    //   sortable:true,
    //   style:{
    //     textAlign:"center",
    //     // 'whiteSpace': 'unset'
    //   },
    //   Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>}
    // },
    // {
    //   Header: <Tooltip title="Tracker Status">Tracker Status</Tooltip>,
    //   accessor: "tracker_status",
    //   style:{
    //     textAlign:"center",
    //     // 'whiteSpace': 'unset'
    //   },
    //   // Cell:this.setIdDiv,
    //   Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>},
    //   sortable:true,
    // },
    // {
    //   Header: <Tooltip title="Last Active">Last Active</Tooltip>,
    //   accessor: "last_active",
    //   style:{
    //     textAlign:"center",
    //     // 'whiteSpace': 'unset'
    //   },
    //   // Cell:this.setIdDiv,
    //   Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>},
    //   sortable:true,
    // },
    // {
    //   Header: <Tooltip title="Share live location">Share</Tooltip>,
    //   accessor:"live_track",
    //   style:{
    //     textAlign:"center",
    //     cursor:"pointer",

    //   },
    //   Cell:props => {
    //     return(
    //        <button className="map-btn" value={props.value} onClick={(e) => {
    //          this.clickshareLoaction(e,props.original.tracker_no,props.original.vehicle_model)
    //        }}><FaShare className="orange"/></button>
    //     )
    //   },
    //   sortable:true,
    // },

    // {
    //   Header: <Tooltip title="Live Track">Live Track</Tooltip>,
    //   accessor:"live_track",
    //   style:{
    //     textAlign:"center",
    //     cursor:"pointer",

    //   },
    //   Cell:props => {
    //     return(
    //        <button className="map-btn" value={props.value} onClick={(e) => {
    //          this.clickMe(e,props.original.tracker_no,props.original.vehicle_model)
    //        }}><FaMapMarker className="orange"/></button>
    //     )
    //   },
    //   sortable:true,
    // },
  ]
        return (
          <div>
          {/*header component start*/}
          <div className="navbar navbar-default">
            <div className="container-fluid pl-0">
                 <div className="navbar-header">
                    <a className="header-logo">
                    <Link to="/lenderdashboard"><img src={matchpointlogo} alt="matchpointlogo" width="300" className="logo"/></Link>
                    </a>
                 </div>
              <div className="nav navbar-nav navbar-right">
              <div className=" list-inline mb-0">
              <div id="profile_menu" className="list-inline-item fs-21 fc-red text-capitalize border-right cursor-pointer usernamemenu "
              onMouseOver={(e) => {
                //console.log('Focused on input');
                //$("#profile_menu").toggle()
              }}
              onMouseLeave={(e) => {
                //console.log('Focused Out input');
                //$("#profile_menu").toggle()
              }}
              >
                Welcome, {this.state.user1}<span className="color-orange">
                
                </span>
                {/* <ul>
                    <li><a href="#" data-toggle="modal" data-target="#resetPassword" >Change Password</a></li>
                   
                </ul> */}
                   <ul>
          
          {this.state.userGroups[0] === '5' && this.state.userGroups[2] === '7' ? (
            <li>
               <Link to="/Api_integration" style={{ color: 'black', textDecoration: 'none',  }} className="custom-link" >
                 
                 <span style={{ textDecoration: 'none' }} >  Api Integration </span>

               </Link>
          </li> ) : null}
        
        </ul>

                
              </div>
              
              <div className="list-inline-item">
                  <a onClick={e=>this.logoutHandler(e)} className="cursor-pointer">
                    <img src={logout} alt="logout-logo" width="30"/>
                  </a>
              </div>
              </div>
              </div>
          </div>
        </div>
        {/*header component end*/}



          {/* Range component start*/}
          <div className="container-fluid pt-35 red1">
            <div className="row plr-20 ">
              {/* <div>


             <Modal  open={open} onClose={this.onCloseModal} center>
            <div id="common_message">
            <p className="common_message_class">{this.state.common_message}</p>
            </div>
           <div id="history_table" className="cls_history_table">
           <div className="row">
             <div className="col-sm-6">
             <div className="order-no"><span>CUSTOMER NAME: </span><span className="marr-20 color">{this.state.gl_cust_name}</span><span>LOS NO: </span><span className="color">{this.state.gl_orderno}</span></div>
             </div>
             <div className="col-sm-6">
             <button className="btn btn-dark print-btn" onClick={this.Print}>Print</button>
           <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Export Excel Report"/>
             </div>
           </div>
           <table className="table table-bordered" id="table-to-xls">
                  <thead>
                    <tr className="text-center">
                      <th className="gray">Date</th>
                      <th className="gray">Parked Location (parked between midnight to 4 AM)</th>
                      <th className="gray">Latitude</th>
                      <th className="gray">Longitude</th>
                      <th className="gray">Distance Covered (kms)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.historydata.map( (item,i) => {
                     return(
                        <tr key={i} className="text-center">
                        <td>{item['date']}</td>
                        <td>{item['last_parked_loc']}</td>
                        <td>{item['last_parked_loc_lat']}</td>
                        <td>{item['last_parked_loc_long']}</td>
                        <td>{item['distance_travelled']}</td>
                        </tr>
                        )
                    })
                    }
                  </tbody>
                </table>
                </div>
                <h2 className="selected_id">
                </h2>
             </Modal>
             </div> */}

             {/* <div className="col-lg-3">
             <select className="monthly list-inline-item gray-shadow"  onChange={this.selectoption}>
                <option className="period-text1" value="0">Vehicle Registration No.</option>
                <option className="period-text1" value="1" name="0.5">Los No.</option>
              </select>
             </div>

             <div className="col-lg-3" style = {{display : this.state.option == 0 ? "": "None" }}>
             <div className="input-group search-input resp-input-group">
             <input type="text" onBlur={this.handlesearchdata1} onKeyPress={this.searchpressHandler1} className="form-control custom-serach-input" placeholder="Vehicle registration no" />
             <FaSearch className="search-icon" onClick={this.searchHandler1} />
             </div>
             </div>

             <div className="col-lg-3" style = {{display : this.state.option == 1 ? "": "None" }}>
             <div className="input-group search-input resp-input-group">
             <input type="text" onBlur={this.handlesearchdata} onKeyPress={this.searchpressHandler} className="form-control custom-serach-input" placeholder="Los no" />
             <FaSearch className="search-icon" onClick={this.searchHandler} />
             </div>
             </div> */}

              <div className="col-lg-6">
                <div className="list-inline" >
                  <Tabs>
                  <span className="period-text">Period:</span>
                  <Container className="all list-inline-item gray-shadow daterangepicker-list-item pd-2" >
                  <Form className="pd-3">
                  <DateRangePicker
                    displayFormat={() => "DD/MM/YYYY"}
                    endDate={this.state.endDate}
                    endDateId="endDate"
                    focusedInput={this.state.focusedInput}
                    isOutsideRange={() => null}
                    onDatesChange={this.handleDateChange}
                    onFocusChange={this.handleFocusChange}
                    startDate={this.state.startDate}
                    startDateId="startDate"
                    isOutsideRange={day => (moment().diff(day) < 0)}
                  />
                  </Form>
                  </Container>

                  <button type="submit" id="submit_id" className="btn btn-dark submit-btn" onClick={this.submitHandler}>Submit</button>
                  <ExcelFile element={<button className="btn btn-md btn-dark export-btn" onClick={this.ExcelFileLoader}>Export Excel Report</button>}>
                    <ExcelSheet data={this.state.tableData} name="Reports">
                      <ExcelColumn label='Sr No.' value="sr_no"/>
                      <ExcelColumn label='Los No.' value="los_no"/>
                      <ExcelColumn label='Booking Date' value="order_date"/>
                      <ExcelColumn label='Vehicle Registration No.' value="vehicle_reg"/>
                      <ExcelColumn label='Customer Name' value="customer_name"/>
                      <ExcelColumn label='Mobile Number' value="cust_phone_no"/>
                      <ExcelColumn label='Gps Installation Status' value="installation_status"/>
                      <ExcelColumn label='Gps Installation Date' value="installation_date"/>
                    </ExcelSheet>
                </ExcelFile>
                  </Tabs>
                </div>
              </div>
            </div>
            <br/>
            
            {/* <p className="text-center red">Today total Installed cases <span>{gl_posts.length}</span> out of <span>{this.state.totalcount.total_installed_count}</span> Devices .</p> */}
            </div>
            {/*Range component end */}



            {/* Dashboardtable component start */}
            <div className="red2">
            <div className="plr-20">
            <ReactTable columns={columns}
                        getTdProps={this.onRowClick}

                        ref={r => (this.reactTable = r)}
                        // onFilteredChange={this.onFilteredChange.bind(this)}
                        // data={gl_posts}
                        data={gl_posts}
                        showPagination={true}
                        pageSizeOptions = {[1000,2000]}
                        defaultPageSize={1000}
                        style={{
                          maxHeight: "630px" // This will force the table body to overflow and scroll, since there is not enough room
                        }}
                        >
              </ReactTable>

              <div className="loading-icon" id="myDiv">
              <img id="loading-image" / >
              </div>
            </div>
            </div>
            {/* Dashboardtable component end */}

            {/* Reset Password model window start here */}

            <div class="modal fade" id="resetPassword" tabindex="-1" role="dialog" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="close closebutton" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fa fa-times"></i></span></button>
                    <h4 class="modal-title">Change Your Account Password</h4>
                  </div>
                  <form href="" method="post" >
                    <div class="modal-body">
                      <div class="form-group">
                        {/* <label for="accountEmail">Account Email</label> */}
                        <span class="help-block">Old Password.</span>
                        <input  id="old_password" type="password" onChange={this.onChange} class="form-control mpassword" required="required" name="old_password" />
                        <span  class="help-block">New Password.</span>
                        <input id="new_password" type="password" minlength="8" onChange={this.onChange} class="form-control mpassword" required="required" name="new_password" />
                        <span class="help-block ">Confirm Password.</span>
                        <input id="confirm_password" type="password" minlength="8" onChange={this.onChange} class="form-control mpassword" required="required" name="confirm_password" />
                      </div>
                      <input type="checkbox" onChange={this.showPassword} /> Show Password
                      <br/>
                      <label for="error-msg" id="lbl_err"></label>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default btn-sm btn-icon" data-dismiss="modal">Cancel</button>
                      <button type="input" id="resetpass" onClick={this.postData} name="submit" value="resetPass" class="btn btn-success btn-sm btn-icon"> Change Password</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          {/* Reset Password model window end here */}

          {/* Reset Password model window start here */}
          <div class="modal fade" id="mdsharelocation" tabindex="-1" role="dialog" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="close closebutton" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fa fa-times"></i></span></button>
                    <h4 class="modal-title">Share Current location </h4>
                  </div>
                  <form href="" method="post" >
                    <div class="modal-body">
                      <div class="form-group">
                        {/* <label for="accountEmail">Account Email</label> */}  

                        <span class="help-block">Please Send following link to share the location.</span>

                        <br/>
                        <label for="error-msg" id="lbl_err_shareloc"></label>
                        {/* <input id="shareon_link" type='text' class="form-control clsshare_location"/> */}
                        <input  id="shareon_emailid" hidden type="email" onChange={this.sharelocValidatation} class="form-control clsshare_location" name="email_id" />
                        {/* <span  class="help-block">Sent to Mobile Number:</span> */}
                        <input id="shareon_mobileno" hidden type="number" minlength="10" onChange={this.sharelocValidatation} class="form-control clsshare_location"  name="mobile_no" />
                        <input id="shareon_trackerno" hidden type="text" class="form-control clsshare_location" required="required" name="trackerno" />
                        <input id="shareon_vname" hidden type="text" class="form-control clsshare_location" required="required" name="vname" />
                        
                      </div>                      
                      <br/>
                      <label for="error-msg" id="lbl_err_shareloc"></label>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-default btn-sm btn-icon" data-dismiss="modal">Cancel</button>
                      {/* <button type="input" id="btnshare_location" onClick={this.postshareLocation} name="submit" value="sharelocation" class="btn btn-success btn-sm btn-icon">Share Location</button> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          {/* Reset Password model window end here */}
                          
           </div>
        )
    }
}
export default Order_status
