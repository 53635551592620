
import AuthService from './Config/AuthService';
import React, { Component,useRef  } from 'react';
import matchpointlogo from './assets/images/matchpointlogo.png';
import './main.css';
import $ from "jquery";
import { Redirect ,Link} from 'react-router-dom'
import host from './components/ipaddress'

import Snackbar from '@material-ui/core/Snackbar';



class Login extends Component {
  constructor(props) {
    super(props)
    const token = localStorage.getItem("token")
    let loggedIn = false

    this.state={
      username :'',
      password:'',
      showgetOtp: false,

        host : host,
    
      loggedIn,      
      dashboard:"",
      group_names:'',
      showOtp: false,
      message: '',
      mobile_number: '',
      otp: '',
      step: 'init',
      items: [],
      isLoaded: false,
      redirectToReferrer: false,
      token: '',
      counter: 0,
      clicks: 0,
     //showgetOtp: false,
     showgetOtp: true,
      showreSendOtp: false,
      showloginWithOtp: false,
      showlogin: true,
      openAlert : false,
      errorMessage : '',
      redirectToReferrer: false,
      demoClick : false,
      validateMobile : true,
      value: '', 
      group_id:'',
      group_names:'',
      fadeOutMobileNumber: false,
    }
        if(token == null){
          loggedIn = false
        }
        else{
          this.CheckUserToken()
        }


    this.submitForm = this.submitForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.CheckUserToken = this.CheckUserToken.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.otpInputRef = React.createRef();

  }

  handleChange = (e) => {
   // this.showSuggestion(e.currentTarget)
    // console.log('...................')
    if (/^\d+$/.test(e.target.value)) {
    
     
      this.setState({
    
        mobile_number: e.target.value,
        
        showgetOtp: true,
        showlogin: false,
        showloginWithOtp: false,
        showreSendOtp: false
        
      })

    } else {
     
      document.getElementById('otp_input').value = '';
      $('#otp_input').prev().hide()
      if(this.state.demoClick){
        this.setState({
          showlogin : false,
        })
      }else{
        this.setState({

          username: e.target.value,
          showgetOtp: false,
          showlogin: true,
          showloginWithOtp: false,
          showreSendOtp: false
        })
      }
    }
    this.setState({ mobile_number: e.target.value })
  }

   
      reSendOtp = (e) => {
           console.log(this.state.mobile_number);
       
        e.preventDefault();
    
        const credentials = { mobile_number: this.state.mobile_number };
        AuthService.generateOtp(credentials).then(res => {      
          if (res.data.response_code === 200 && res.data.is_success === true) {        
            
          }
        }).catch(err => console.log(err, 'error while resending OTP'))
      }
    

  getOtp = (e) => {
    if(this.state.validateMobile){
      e.preventDefault();

      $('#resend_otp').css({
        pointerEvents: 'none', disableResendOtpButton: true,
        color : "red",
      })    
         const credentials = { mobile_number: this.state.mobile_number };
       
       //  console.log('mobile number is', this.state.mobile_number, '.............', credentials)
      
         AuthService.generateOtp(credentials).then(res => {
     
     //   console.log('opt response',res)
         if (res.data.response_code === 200 && res.data.is_success === true) {
          
        // console.log('generate otp data', res.data)
          
         // this.state.mobile_number = res.data.mobile_number;
          
          this.state.step = 'otp';
          this.setState({
            showgetOtp: false,
            // showreSendOtp: true,
            showlogin: false,  
            fadeOutMobileNumber: true,        
            showOtp: true,
            step: 'otp',
            
          });

         
         this.otpInputRef.current.focus();  // Focus on the OTP input field
          setTimeout(() => {
            this.setState({
              showreSendOtp: true,
            });
          }, 25000); // 25 seconds
        
        }else{
          this.setState({
            openAlert : true,
            errorMessage : " In valid  Mobile Number "
          })
        }
      })
      
      .catch(err => {
        this.setState({
          showgetOtp: true,
          showreSendOtp: false,
          showlogin: false,
          // showloginWithOtp: false,
          showOtp: false,
        })
        console.log(err,'error in getting otp')
      })

      setTimeout(function () {
        $('#resend_otp').css({
          pointerEvents: 'auto', disableResendOtpButton: false,
          color : "red",
        })
      }, 30000)
    
    }else
    {
      this.setState({
        openAlert : true,
         errorMessage : "Enter a valid number"
      })
    }
  }
 
  otpValue = (e) => {
    // this.showSuggestion(e.currentTarget)
      
    this.setState({
      otp: e.target.value
    })
    if (e.target.value.length <= 6) {
      
     // console.log('lenght of otp is' + e.target.value )
      this.setState({
               
        showlogin: false,
        showloginWithOtp: true
      })
      //host:'http://localhost:8000',
      //host:'http://157.245.106.11:8000',
      //host:'https://lenderdbbackend01.do-blr.mpgpsdc.com',
     
    }else{
      this.setState({
        openAlert : true,
        errorMessage : "OTP entered is more than 6 digit. Enter Valid OTP"
      
      })
    }
  }

  
  mobileNumberValidation = (e) => { 
    if(this.state.showgetOtp){       
       
     // console.log('mobile number : = ',e.currentTarget.value.length)   
      if(e.currentTarget.value.length !== 10)
      {
        this.setState({
          validateMobile : false
         
        })        
      }else{
        this.setState({
          
          validateMobile : true
        })
      }
    } 
  }

   
  onChange(e){
  this.setState({
    [e.target.name]:e.target.value
  })
}

CheckUserToken()
{
  
  let data = {}
  $.ajax({
      url: this.state.host+'/v1/mpgps/tractorjunctionDashboard/get_dashboard_data2/',
      dataType: 'json',
      cache: false,
      method:'post',
      data:data,
      beforeSend: function(request) {
        request.setRequestHeader("authorization", localStorage.getItem("token"));
        request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number"),"username":localStorage.getItem("username")}));
        $("#loading-image").show();
        $(".loading-icon").show();
      },
      success: function(data) {

        if(data.is_success == true){

          this.setState({
            loggedIn:true,
            
          })


        }else{
            if(data.response_code == 500){
               alert("Internal server Error.......src/main.css")
            }else if(data.response_code == 401){
                 alert("Unauthorised user")
            }else{
                alert(data.response_message)
            }
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.url, status, err.toString());
        console.log('fail');
        if(err.toString() == "Forbidden"){
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          localStorage.removeItem("mobile_number");
          localStorage.removeItem("username");
          localStorage.removeItem("group")
          this.setState({
            loggedIn:false
          })
          this.props.history.push('/');
         }
      }.bind(this)
    });
}

componentDidMount(){

}
// login function start

  changeHandler = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

 submitForm = (e) => {
                 e.preventDefault()
                 const {otp,mobile_number,username} = this.state
    

     console.log("zz >> ",this.state)
   let data = JSON.stringify({'mobile_number':this.state.mobile_number,'otp':this.state.otp,'username':this.state.username})

    $.ajax({
        url: this.state.host+'/v1/mpgps/tractorjunctionDashboard/loginAuthorisation/',
        dataType: 'json',
        cache: false,
        method:'post',
        data:data,
        success: function(data) {
          this.setState({
              entries: data
            });
         // console.log('success',data);

          if(data.is_success == true){
            // localStorage.setItem("token","dsfsfsfsgsgf")
            localStorage.setItem('token', data.data.token)
            localStorage.setItem('user', data.data.user)
            localStorage.setItem('otp',data.data.otp)
            localStorage.setItem('mobile_number',data.data.mobile_number)
            localStorage.setItem('username',data.data.username)
            localStorage.setItem('cache focus date',new Date())
            localStorage.setItem('user_groups',data.data.user_groups)

           

            { 
         //     console.log('group value  set =',data.data.user_groups)
            this.setState({
              loggedIn:true,
              dashboard:data.data.dashboard,
              user_groups:data.data.user_groups
            })
          }
          // Pass user and token values to App.js and redirect
       
          //   console.log('group value=',data.data.user_groups)

          // this.handleClick();
          }else{
             
              if(data.response_code == 500){
                  // alert("Internal server Error....")
              }else if(data.response_code == 401){
                   alert("Unauthorised user")
              }else{
                  alert("invalid  user...")
              }
              alert("invalid user...")
          }
        }.bind(this),
        error: function(xhr, status, err) {
          console.error(this.props.url, status, err.toString());
       //   console.log('fail');
         alert(" Please enter valid details...")
        }.bind(this)
      });

      
}
         
    render(){
         
     
      if(this.state.loggedIn){
        let normaldashboard =['ICICI']
        let dashboard_demo =['TRACTORJUNCTION','DEMO_EMPLOYEE']        
        
      //  console.log("dashboard_demo.includes(this.state.dashboard)",dashboard_demo.includes(this.state.dashboard))
        //console.log("normaldashboard.includes(this.state.dashboard)",normaldashboard.includes(this.state.dashboard))
          if (normaldashboard.includes(this.state.dashboard))
          {
            return <Redirect to="/homes" />
          }
          if (dashboard_demo.includes(this.state.dashboard))
          {
            return <Redirect to="/home" />
          }
          else{
            return <Redirect to="/tractorjunctionDashboardnotfound" />
          }
      }

      const {mobile_number} = this.state
      const mobileNumberClassName = this.state.fadeOutMobileNumber
      ? "form-control custom-input fade-out" // Define a CSS class for fading out
      : "form-control custom-input";
          
      
        return(
          
          <div className="bgbody">
          <div className="container">
             <div className="row h100 align-center justify-center ">
               <div className="content-padd">
                 <div className="text-center">
                   <img src={matchpointlogo} alt="matchpointlogo"  className="w85"/>
                 </div>
                   <h1 className="text-center text-login">Login</h1>

             <form onSubmit={this.submitForm}>
                    
               <div className="form-group">
                    <input type="text" id="mobile_number"     autoComplete="off" className="form-control  custom-input" 
                      className={mobileNumberClassName}
                       onKeyUp={this.mobileNumberValidation} 
                       placeholder='Enter Mobile Number'
                       value={mobile_number}
                       onChange={this.handleChange} name="mobile_number" >
                   </input>
              </div>

              <div className="from-group">

                      <input id="otp_input"  type="text" autoComplete="off" className="form-control" placeholder="Enter OTP"
                      style={{ display: this.state.showOtp ? '' : 'none' }} 
                      onChange={this.otpValue}
                      onKeyUp={(e) => {
                      if(e.keyCode === 13){
                      $('#mobile_login').click() } }}
                      ref={this.otpInputRef}  >  

                       </input> 
               </div> <br>
                     </br>
                     
              <div className='from-group'>
                    <button   className=" orange-btn sign_in_btn" style={{ display: this.state.showgetOtp ? '' : 'none' }} onClick={this.getOtp}> Get OTP </button> 
              </div>
              <div className='from-group'>
              <label className="" style={{ display: this.state.showreSendOtp ? '' : 'none' }} onClick={this.reSendOtp} id="resend_otp">Resend OTP</label>
              </div>    
             
              <div className="form-group">
                 <button id="username_login" className="  orange-btn sign_in_btn "  style={{ display: this.state.showOtp ? '' : 'none' }}  onClick={this.submitForm}> Sign in</button>
             </div>
              
           </form>                                 
       </div>
     </div>
    </div>
          
          <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={4000}
          onClose= {(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
      )
    }
}

export default Login


