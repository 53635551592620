import React, { Component } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css"
import { FaMapMarker } from 'react-icons/fa';
import { FaSearch } from 'react-icons/fa';
import { FaShare } from 'react-icons/fa';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import Icon from '../icon';
import {Form, Input, FormGroup, Container, Label} from 'reactstrap';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import Modal from 'react-responsive-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import ReactExport from "react-export-excel";
import moment from 'moment';
import { Link , Redirect } from 'react-router-dom';

import matchpointlogo from '../assets/images/matchpointlogo.png';
import logout from '../assets/images/logout.png';
import orange from '../assets/images/orange.png';
import '../main.css'
import { render } from "react-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import Dialog from 'react-dialog'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { CSVLink, CSVDownload } from "react-csv";
import Background from '../assets/images/New_Project.png';
import ReactTooltip from "react-tooltip";
import "react-table/react-table.css";
import axios from "axios";
import Flag from "react-flags";
import "antd/dist/antd.css";
import { Table, Tooltip } from "antd";

import Timekeeper from 'react-timekeeper';
import Grid from '@material-ui/core/Grid';
import host from './ipaddress';
import { counter } from '@fortawesome/fontawesome-svg-core';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import MenuList from './dropdownList';
import Select from 'react-select';
import Common_function from './Common_function';
import { osName,osVersion,browserName,deviceType } from 'react-device-detect';
import ApiUrlCall from './Api/apiurl';



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var gl_requestcode;
var gl_requesttype;
var gl_orderno;
var gl_list = [];
var gl_req_data = []
var timercounter = 0;

class BatteryDisconnectionReport extends Component{
  constructor(props){
    super(props);

    const token = localStorage.getItem("token")
    let loggedIn = true
    if(token == null){
      loggedIn = false
    }
    this.state={
      loggedIn,

      value: '',
      date: null,
      host : host ,
      totalCount:'',
      startTime: "12:00 am",
      showStartTime: false,
      showEndTime: false,
      endTime: "11:59 pm",
      sendEndTime: "23:59",
      sendStartTime: "00:00",
      startDate: moment().subtract(0,"day"),
      currentStartDate: moment().subtract(0,"day"),
      endDate: moment(),
      checkebox_value: [],
      user1 : localStorage.getItem("username"),
      userGroups: localStorage.getItem('user_groups'),
      
      lonNoList : [],
      is_checked: true,
      options : [],
      tableData : [],
      isDisabled : false,
      flag : true,

    }
    this.onChange = this.onChange.bind(this);
    this.postData = this.postData.bind(this);
    this.showPassword = this.showPassword.bind(this);   

  }

componentDidMount(){
  this.showLosNumber()
}

componentWillUnmount = () => {
  clearInterval(this.interval)
}


onRowClick = (state, rowInfo, instance) => {
  return {
    onClick: (e, handleOriginal) => {
      if (handleOriginal) {
        handleOriginal();
      }
      $(this).remove();
    }
  };
};

postData(e) {
  e.preventDefault()
    var inputs = document.getElementsByClassName('mpassword');
    let reqdata = {}
    reqdata['mobile_number']=localStorage.getItem('mobile_number')
    Array.prototype.forEach.call(inputs, function(x) {
      reqdata[x.name] = x.value
    })
    $("#loading-image").show();
    $(".loading-icon").show();
    let formdata = reqdata
    const forgeturl = this.state.host + '/v1/mpgps/tractorjunctionDashboard/change_password/'
    axios.post(forgeturl, formdata, {
      headers: {
        'Authorization': localStorage.getItem('token'),
      },
    })
      .then(response => {
        $("#loading-image").hide();
        $(".loading-icon").hide();
        if (response.data.is_success) {
          document.getElementById('resetpass').disabled = true;        
          document.getElementById('lbl_err').innerHTML = response.data.data.message;
          document.getElementById('lbl_err').style.color = 'green';
          setTimeout(() => {
              //this.logoutHandler()
            },2000);

        } else {
          document.getElementById('lbl_err').innerHTML = response.data.response_message;
          document.getElementById('lbl_err').style.color = 'red';
        }
      })
      .catch(error => {
        console.log(error)  
        $("#loading-image").hide();
        $(".loading-icon").hide();      
        if (document.getElementById('lbl_err').innerHTML !== null) {
          document.getElementById('lbl_err').innerHTML = error;
        }
      }) 
}



onChange(e) {

  if (["new_password","confirm_password"].includes(e.target.id))
  {
    if (document.getElementById('new_password').value ==
              document.getElementById('confirm_password').value) {
          document.getElementById('resetpass').disabled = false;
          document.getElementById('lbl_err').innerHTML = "Matched";
          document.getElementById('lbl_err').style.color = 'green';
        } else {          
          document.getElementById('lbl_err').innerHTML = "Not Matched"
          document.getElementById('lbl_err').style.color = 'red';
          document.getElementById('resetpass').disabled = true;
      }
  }
}

selectStartTime = (newTime) => {
  this.setState({
    startTime: newTime.formatted12,
    sendStartTime: newTime.formatted24
  })
}


showStartTimeContent = () => {
  $('#end_date_cal').slideUp()
  $('#start_date_cal').slideUp()
  if (this.state.showStartTime) {
    this.setState({
      showStartTime: false,
      showEndTime: false
    })
  } else {
    this.setState({
      showStartTime: true,
      showEndTime: false
    })
  }
}


selectEndTime = (newTime) => {
  this.setState({
    endTime: newTime.formatted12,
    sendEndTime: newTime.formatted24
  })
}

showEndTimeContent = () => {
  $('#end_date_cal').slideUp()
  $('#start_date_cal').slideUp()
  if (this.state.showEndTime) {
    this.setState({
      showEndTime: false,
      showStartTime: false
    })
  } else {
    this.setState({
      showEndTime: true,
      showStartTime: false
    })
  }
}

showLosNumber = () =>{
  $.ajax({
    url: this.state.host+'/v1/mpgps/tractorjunctionDashboard/get_tractor_junction_data/',
    dataType: 'json',
    type:'post',
    data:{},
    beforeSend: function(request) {
        request.setRequestHeader("authorization", localStorage.getItem("token"));
        request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number" )}));
        $("#loading-image").show();
        $(".loading-icon").show();
      },
    success: function(data) {
   //   console.log("show data..",data)
      this.setState({
        lonNoList : data.data,
        totalCount : data.total_count,
      },() => {
        var counter = 0

        this.state.lonNoList.map(data => {
            var option = {}
            option["label"] = data.lan_no
            option["value"] = data.serial_no
            counter = counter+1
            this.state.options.push(option)
            if (this.state.totalCount == counter){
              this.sendDataToGenerateByDefaultReport()
              this.interval = setInterval(() => {
                this.sendDataToGenerateByDefaultReport()
              }, 300000)  
              $("#loading-image").hide()
             $(".loading-icon").hide()
            }
        })  
      }
      )
    }.bind(this),
    error: function(xhr, status, err) {
      console.error(this.props.url, status, err.toString());
      console.log('fail');
   //   alert("Internal Server Error");
      $("#loading-image").hide();
      $(".loading-icon").hide();
      if(err.toString() == "Forbidden"){
       //this.logoutHandler()
      }
    }.bind(this)
  });
}


logoutHandler =(e) =>{
  var user_id = localStorage.getItem("user")
  var sha256 = require('js-sha256');
  var userid_enc = sha256(user_id);

  var active_session = {
    start_time : Common_function.ServerDateTime(localStorage.getItem("cache focus date")),
    end_time: Common_function.ServerDateTime(new Date())
  }
   console.log(active_session ,"logout time detail")
  var device_detail = [{
   event_id : 1,
  unique_id : userid_enc,
    os_version : osVersion,
    model_name: osName,
    value : active_session,user_id:user_id,
  
  }]
  
  const analytics_details = {
    data : device_detail , source : 2
  };

  
 JSON.stringify(analytics_details)

//  console.log("hello..", analytics_details)
        
  ApiUrlCall.send_analytics(analytics_details).then(response => {
     
    if (response.status === 200) {
      return

    }
  }
  )
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("mobile_number");
  localStorage.setItem('cache focus date','')

  gl_requestcode="";
  gl_requesttype="";
  gl_orderno="";
  gl_list = [];
  gl_req_data = []
  timercounter = 0;
  setTimeout(function(){
    window.location.reload()
  }, 1000);  
}

showPassword = (e) => {
  var inputs = document.getElementsByClassName('mpassword');
  Array.prototype.forEach.call(inputs, function(x) {
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  })
}


onChange2 = (e) =>{
  this.setState({
    checkebox_value : e
  },() => {
    if (this.state.checkebox_value.length == 50){
      this.setState({
        isDisabled : true,
      })
      alert("You can select maximum of 50 checkbox.")
    }else{
      this.setState({
        isDisabled : false,
      })
    }
  })
} 

createCurrentDateTime = (date) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var sec = date.getSeconds()
  if(month <= 9){
    month = '0'+month
  }
  if(day <= 9){
    day = '0'+day
  }
  var datetime = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + sec
  return datetime
}

createServerDateTime = (date, time) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  if(month <= 9){
    month = '0'+month
  }
  if(day <= 9){
    day = '0'+day
  }
  var datetime = year + "-" + month + "-" + day + " " + time + ":" + "00"
  return datetime
}

dateValidation(start, end) {
  var start = new Date(start);
  var end = new Date(end);  
  if (start > end) {
    return false
  } else {
    return true
  }
}

sendDataToGenerateByDefaultReport = (e) =>{
  const {endDate,startDate,sendEndTime,sendStartTime,flag,currentStartDate} = this.state
  if (flag == true){
     var start_date_time = this.createServerDateTime(currentStartDate._d,sendStartTime)
  }
  else {
    var start_date_time = this.createCurrentDateTime(currentStartDate._d)
  }
  var current_data = []
  var end_date_time = this.createServerDateTime(endDate._d,sendEndTime)
  var dateValidate = this.dateValidation(start_date_time , end_date_time)
  if(dateValidate){
    var data = JSON.stringify({"start_date" : start_date_time,
    "end_date" : end_date_time,"report_type" : "by_default", "serial_and_lan__no_list" : []})

    $.ajax({
      url: this.state.host+'/v1/mpgps/tractorjunctionDashboard/battery_disconnection_report/',
      dataType: 'json',
      type:'post',
      data:data,
      beforeSend: function(request) {
          request.setRequestHeader("authorization", localStorage.getItem("token"));
          request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number")}));
          $("#loading-image").show();
          $(".loading-icon").show();
        },
      success: function(data) {

       // console.log(data, this.state.tableData)
      //    console.log("called battery dis...",this.state.tableData)

        //current_data = this.state.tableData
        data.data.map(data_data =>{
          current_data.push(data_data)
        })
        this.state.tableData.map(data_data =>{
          current_data.push(data_data)
        })



        this.setState({
          tableData :  current_data,
          flag : false,
          currentStartDate: moment().subtract(0,"day"),
        },() =>{
          if(this.state.tableData.length == 0){
              alert("No data found.")
          }
        })
        $("#loading-image").hide();
        $(".loading-icon").hide();  
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.url, status, err.toString());
        console.log('fail');
        alert("Internal Server Error");
        $("#loading-image").hide();
        $(".loading-icon").hide();
        if(err.toString() == "Forbidden"){
         //this.logoutHandler()
        }
      }.bind(this)
    });
  }else{
    alert("Please select valid date and time")
  }
}

sendDataToGenerateReport = (e) =>{
  clearInterval(this.interval)
  const {endDate,startDate,sendEndTime,sendStartTime} = this.state
  if (this.state.startDate == null){
      alert("Please select start date.")
      return
  }else {
    var start_date_time = this.createServerDateTime(startDate._d,sendStartTime)
  }
  if(this.state.endDate == null){
    alert("Please select end date.")
    return
  }else{
    var end_date_time = this.createServerDateTime(endDate._d,sendEndTime)
  }
  
  if(this.state.checkebox_value.length == 0){
    alert("Please select los number.")
    return
  }
  var dateValidate = this.dateValidation(start_date_time , end_date_time)
  if(dateValidate){
    const list = []
    this.state.checkebox_value.map(data =>{
        var dict = {"serial_no": data.value,"lan_no" : data.label}
        list.push(dict)
    })
    var data = JSON.stringify({"start_date" : start_date_time,
    "end_date" : end_date_time,
    "serial_and_lan__no_list": list,
    "report_type": "by_serial_no"})
    
    console.log(data,"ooooooooooooo")

    $.ajax({
      url: this.state.host+'/v1/mpgps/tractorjunctionDashboard/battery_disconnection_report/',
      dataType: 'json',                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
      type:'post',
      data:data,
      beforeSend: function(request) {
          request.setRequestHeader("authorization", localStorage.getItem("token"));
          request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number")}));
          $("#loading-image").show();
          $(".loading-icon").show();
        },
      success: function(data) {
        console.log(data)
        this.setState({
          tableData : data.data
          
          //tableData : [{"sr_no" : 1,"serial_number":54049193,"total_duartion":1234,"los_no":1000031722 ,"home_location" : "F 503 KALARRESIDENCY NR TIMES OF INDIA,PRESS ROAD RADIO MIRCHI TOWER SATELLITE,NR RADIO MIRCHI TOWER,AHMEDABAD,380015","total_duartion_hr_min" : "0 Hr:20 Min :34 Sec"},{"sr_no" : 2,"serial_number":54072775,"total_duartion":5678,"los_no":1000040597 ,"home_location" : "HOUSE NO 101 POCKET E -19,SECTOR -3 ROHINI,DELHI,110085","total_duartion_hr_min" : "0 Hr:20 Min :34 Sec"},{"sr_no" : 3,"serial_number":54525538,"total_duartion":7456,"los_no":1000212873 ,"home_location" : "439 Krishna Park Society, Bus Stop, Near Aas Pass Temple Godadara, Chorasal, Parvat, Surat nGujarat - 395010","total_duartion_hr_min" : "0 Hr:20 Min :34 Sec"},{"sr_no" : 4,"serial_number":862549048113206,"total_duartion":8823,"los_no":1400027714 ,"home_location" : "37/B SHUMS MANZIL 2ND FLR ROOM 30 DOCKYARDSTN MAZGAON MUMBAI MUMBAI MAHARASHTR AINDIA 400010","total_duartion_hr_min" : "0 Hr:20 Min :34 Sec"}]
        },() =>{
          if(this.state.tableData.length == 0){
              alert("No data found.")
          }
        })
        $("#loading-image").hide();
        $(".loading-icon").hide();  
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.url, status, err.toString());
        console.log('fail');
        alert("Internal Server Error");
        $("#loading-image").hide();
        $(".loading-icon").hide();
        if(err.toString() == "Forbidden"){
         //this.logoutHandler()
        }
      }.bind(this)
    });
  }else{
    alert("Please select valid date and time")
  }
}

convertTime =(sec) =>{
  var hours = Math.floor(sec/3600);
  (hours >= 1) ? sec = sec - (hours*3600) : hours = '0';
  //(hours >= 10 && hours <= 1) ? sec = sec - (hours*3600) : hours = '0'+hours;
  var min = Math.floor(sec/60);
  (min >= 1) ? sec = sec - (min*60) : min = '00';
  (sec < 1) ? sec='00' : void 0;

  (min.toString().length == 1) ? min = '0'+min : void 0;    
  (sec.toString().length == 1) ? sec = '0'+sec : void 0; 

  return hours+' Hr:'+min+' Min :'+sec+" Sec";
}

clickMe(event,tracker){    
  let reqdata = {}
  reqdata['serial_number']= tracker
  reqdata['vname'] = ""
  reqdata['mobile_no'] = ""
  reqdata['email_id'] = ""
  $("#loading-image").show();
  $(".loading-icon").show();

  const forgeturl = this.state.host+'/v1/mpgps/tractorjunctionDashboard/share_location/'
  axios.post(forgeturl, reqdata, {
    headers: {
      'Authorization': localStorage.getItem('token'),
    },
  })
    .then(response => {
      $("#loading-image").hide();
      $(".loading-icon").hide();
      var link = response.data.share_link;
      //console.log('link ',link)
      window.open(link)
     })
    .catch(error => {
      //console.log(error)  
      alert(error)
    })
}


render(){
  if(this.state.loggedIn === false){
      return <Redirect to="/" />
    }

  const headers = [
    { label: "Sr No.", key: "sr_no" },
    { label: "Order No.", key: "lan_no" },
    { label: "Location", key: "address" },
    { label: "Disconnected Event Time", key: "event_time" },
  ];

  const columns=[
  {
    Header: <Tooltip title="Sr No.">Sr No.</Tooltip>,
    accessor: "sr_no",
    style:{
      textAlign:"center"
    },
    Cell: (row) => { return <div>{row.index+1}</div>},
    width:100,
    minWidth:100,
    maxWidth:100
  },
  {
    Header: <Tooltip title="Order No.">Los No.</Tooltip>,
    accessor: "lan_no",
    style:{
      textAlign:"center"
    },
    Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>},
    width:100,
    minWidth:100,
    maxWidth:100,
  },    
  {
    Header: <Tooltip title="Location">Location</Tooltip>,
    accessor: "address",
    sortable:true,
    style:{
      textAlign:"center",
      // 'whiteSpace': 'unset'
    },
    Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>},
    width:600,
  },
  {
    Header: <Tooltip title="Disconnected Event Time">Disconnected Event Time</Tooltip>,
    accessor: "event_time",
    style:{
      textAlign:"center",
      float:'right',
    },
    Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>}
  },
  {
    Header: <Tooltip title="Live Track">Live Track</Tooltip>,
    accessor:"live_track",
    style:{
      textAlign:"center",
      cursor:"pointer",

    },
    Cell:props => {
      return(
         <button className="map-btn" value={props.value} onClick={(e) => {
           this.clickMe(e,props.original.serial_no)
         }}><FaMapMarker className="orange"/></button>
      )
    },
  },
  ]



  return (
    <div>
      {/*header component start*/}
      <div className="navbar navbar-default">
        <div className="container-fluid pl-0">
              <div className="navbar-header">
                <a className="header-logo">
                <Link to="/lenderdashboard"><img src={matchpointlogo} alt="matchpointlogo" width="300" className="logo"/></Link>
                </a>
              </div>
          <div className="nav navbar-nav navbar-right">
          <div className=" list-inline mb-0">
          <div id="profile_menu" className="list-inline-item fs-21 fc-red text-capitalize border-right cursor-pointer usernamemenu "
          onMouseOver={(e) => {
            //$("#profile_menu").toggle()
          }}
          onMouseLeave={(e) => {
            //$("#profile_menu").toggle()
          }}
          >
            Welcome, {this.state.user1}<span className="color-orange">
            </span>
            {/* <ul>
                <li><a href="#" data-toggle="modal" data-target="#resetPassword" >Change Password</a></li>
            </ul>  */}
             <ul>
          
          {this.state.userGroups[0] === '5' && this.state.userGroups[2] === '7' ? (
            <li>
               <Link to="/Api_integration" style={{ color: 'black', textDecoration: 'none',  }} className="custom-link" >
                 
                 <span style={{ textDecoration: 'none' }} >  Api Integration </span>

               </Link>
          </li> ) : null}
        
        </ul>

          </div>
          
          <div className="list-inline-item">
              <a onClick={e=>this.logoutHandler(e)} className="cursor-pointer">
                <img src={logout} alt="logout-logo" width="30"/>
              </a>
          </div>
          </div>
          </div>
      </div>
    </div>
    {/*header component end*/}

      {/* Range component start*/}
      <div className="container-fluid pt-35 red1">
      <Grid spacing={2} container >
        <Grid item xs>
          <p className="period-text1">Los No</p>
          <div className="gray-shadow" style = {{marginLeft : "13px"}}>
            <div className="custom-serach-input1">
              <ReactMultiSelectCheckboxes
              components={{ MenuList }}
              options={this.state.options} 
              onChange={this.onChange2.bind(this)}
              placeholderButtonLabel = "Select Los No&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
              isDisabled = {this.state.isDisabled}
              maxMenuHeight = {150}
              />
            </div>
          </div>  
        </Grid>

        <Grid item xs>
          <p className="period-text1" >Start Time</p>
          <div onClick={this.showStartTimeContent} className="time_div">
            <p>{this.state.startTime}</p>
          </div>
          {this.state.showStartTime &&
            <div style={{ position: "absolute", zIndex: "9" }}>
              <Timekeeper
                time={this.state.startTime}
                switchToMinuteOnHourSelect
                onDoneClick={() => {
                  this.setState({
                    showStartTime: false
                  })
                }}
                onChange={this.selectStartTime}
              />
            </div>
          }
        </Grid>

        <Grid item xs>
          <p className="period-text1" >End Time</p>
          <div onClick={this.showEndTimeContent} className="time_div">
            <p>{this.state.endTime}</p>
          </div>
          {this.state.showEndTime &&
            <div style={{ position: "absolute", zIndex: "9" }}>
              <Timekeeper
                time={this.state.endTime}
                switchToMinuteOnHourSelect
                onDoneClick={() => {
                  this.setState({
                    showEndTime: false
                  })
                }}
                onChange={this.selectEndTime}
              />
            </div>
          }
        </Grid>

        <Grid item xs>
            <p className="period-text1">Period</p>
            <Container className="all list-inline-item gray-shadow daterangepicker-list-item pd-2" >
            <Form className="pd-3">
            <DateRangePicker
                displayFormat={() => "DD/MM/YYYY"}
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                isOutsideRange={day => (moment().diff(day) < 0)}
              />
            </Form>
            </Container>
        </Grid>

        <Grid item xs>
            <p className="period-text1"></p>
            <button type="submit" id="submit_id"   style={{ marginTop: "25px",marginLeft : "20px" }} className="btn btn-dark submit-btn" onClick={this.sendDataToGenerateReport}>Generate Report</button>
        </Grid>

        <Grid item xs>
            <p className="period-text1"></p>
            <ExcelFile element={<button className="btn btn-md btn-dark export-btn"  style={{ marginTop: "25px" }} onClick={this.ExcelFileLoader}>Export Excel Report</button>}>
                <ExcelSheet data={this.state.tableData} name="Reports">
                  <ExcelColumn label='Sr No.' value="sr_no"/>
                  <ExcelColumn label='Los No.' value="lan_no"/>
                  <ExcelColumn label='Location' value="address"/>
                  <ExcelColumn label='Disconnected Event Time' value="event_time"/>
                </ExcelSheet>
            </ExcelFile>
        </Grid>
      </Grid>
        <br/>
        </div>
        {/*Range component end */}

        {/* Dashboardtable component start */}
          <div className="red2">
            <div className="plr-20">
              <ReactTable columns={columns}
                  getTdProps={this.onRowClick}
                  ref={r => (this.reactTable = r)}
                  data={this.state.tableData}
                  showPagination={true}
                  defaultPageSize={10}
                  style={{
                    maxHeight: "630px" // This will force the table body to overflow and scroll, since there is not enough room
                  }}>
                </ReactTable>
              <div className="loading-icon" id="myDiv">
                <img id="loading-image" / >
              </div>
            </div>
          </div>
        {/* Dashboardtable component end */}

        {/* Reset Password model window start here */}
        <div class="modal fade" id="resetPassword" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close closebutton" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fa fa-times"></i></span></button>
                <h4 class="modal-title">Change Your Account Password</h4>
              </div>
              <form href="" method="post" >
                <div class="modal-body">
                  <div class="form-group">
                    {/* <label for="accountEmail">Account Email</label> */}
                    <span class="help-block">Old Password.</span>
                    <input  id="old_password" type="password" onChange={this.onChange} class="form-control mpassword" required="required" name="old_password" />
                    <span  class="help-block">New Password.</span>
                    <input id="new_password" type="password" minlength="8" onChange={this.onChange} class="form-control mpassword" required="required" name="new_password" />
                    <span class="help-block ">Confirm Password.</span>
                    <input id="confirm_password" type="password" minlength="8" onChange={this.onChange} class="form-control mpassword" required="required" name="confirm_password" />
                  </div>
                  <input type="checkbox" onChange={this.showPassword} /> Show Password
                  <br/>
                  <label for="error-msg" id="lbl_err"></label>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-default btn-sm btn-icon" data-dismiss="modal">Cancel</button>
                  <button type="input" id="resetpass" onClick={this.postData} name="submit" value="resetPass" class="btn btn-success btn-sm btn-icon"> Change Password</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      {/* Reset Password model window end here */}
                          
    </div>
        )
    }
}
export default BatteryDisconnectionReport
