//const host = 'http://157.245.106.11:8002'

//const host = "https://lenderdbbackend01.do-blr.mpgpsdc.com"

//const host ="http://143.110.240.160"

const host = "https://tractorjunction.do-blr.mpgpsdc.com"

 // const host ="http://139.59.44.181"

  //const host ="http://139.59.44."

   //const host = "http://157.245.106.11:8000"
  
  export default host
  