import React, { Component } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css"
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-calendar/dist/Calendar.css';
import "react-tabs/style/react-tabs.css";
import { Link , Redirect } from 'react-router-dom';
import matchpointlogo from '../assets/images/matchpointlogo.png';
import logout from '../assets/images/logout.png';
import '../main.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import axios from "axios";
import "antd/dist/antd.css";
import { Table, Tooltip } from "antd";
import Grid from '@material-ui/core/Grid';
import host from './ipaddress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaTrashAlt } from 'react-icons/fa';
import '../App.css'
import Common_function from './Common_function';
import { osName,osVersion,browserName,deviceType } from 'react-device-detect';
import ApiUrlCall from './Api/apiurl';



var gl_requestcode;
var gl_requesttype;
var gl_orderno;
var gl_list = [];
var gl_req_data = []
var timercounter = 0;

class CollectionTeam extends Component{
  constructor(props){
    super(props);

    const token = localStorage.getItem("token")
    let loggedIn = true
    if(token == null){
      loggedIn = false
    }
    this.state={
      loggedIn,

      value: '',
      date: new Date(),
      host : host ,
      totalCount:'',
      startTime: "12:00 am",
      showStartTime: false,
      showEndTime: false,
      endTime: "11:59 pm",
      sendEndTime: "23:59",
      sendStartTime: "00:00",
      startDate: new Date(),
      endDate: new Date(),
      checkebox_value: [],
      user1 : localStorage.getItem("username"),
      userGroups: localStorage.getItem('user_groups'),

      lonNoList : [],
      is_checked: true,
      //radius : 0,
      options : [],
      tableData : [],
      isDisabled : false,
      mobileNumber: '',
      clickedMobileNumber: null,
      member_name : '',
      errorMessage: '', 

    }
    this.onChange = this.onChange.bind(this);
    this.postData = this.postData.bind(this);
    this.showPassword = this.showPassword.bind(this);    
  }

componentDidMount(){
  this.showCollectionData()
}

onRowClick = (state, rowInfo, instance) => {
  return {
    onClick: (e, handleOriginal) => {
      if (handleOriginal) {
        handleOriginal();
      }
      $(this).remove();
    }
  };
};

postData(e) {
  e.preventDefault()
    var inputs = document.getElementsByClassName('mpassword');
    let reqdata = {}
    reqdata['username']=localStorage.getItem('username')
    Array.prototype.forEach.call(inputs, function(x) {
      reqdata[x.name] = x.value
    })
    $("#loading-image").show();
    $(".loading-icon").show();
    let formdata = reqdata
    const forgeturl = this.state.host + '/v1/mpgps/tractorjunctionDashboard/change_password/'
    axios.post(forgeturl, formdata, {
      headers: {
        'Authorization': localStorage.getItem('token'),
      },
    })
      .then(response => {
        $("#loading-image").hide();
        $(".loading-icon").hide();
        if (response.data.is_success) {
          document.getElementById('resetpass').disabled = true;        
          document.getElementById('lbl_err').innerHTML = response.data.data.message;
          document.getElementById('lbl_err').style.color = 'green';
          setTimeout(() => {
              //this.logoutHandler()
            },2000);

        } else {
          document.getElementById('lbl_err').innerHTML = response.data.response_message;
          document.getElementById('lbl_err').style.color = 'red';
        }
      })
      .catch(error => {
        console.log(error)  
        $("#loading-image").hide();
        $(".loading-icon").hide();      
        if (document.getElementById('lbl_err').innerHTML !== null) {
          document.getElementById('lbl_err').innerHTML = error;
        }
      }) 
}



onChange(e) {

  if (["new_password","confirm_password"].includes(e.target.id))
  {
    if (document.getElementById('new_password').value ==
              document.getElementById('confirm_password').value) {
          document.getElementById('resetpass').disabled = false;
          document.getElementById('lbl_err').innerHTML = "Matched";
          document.getElementById('lbl_err').style.color = 'green';
        } else {          
          document.getElementById('lbl_err').innerHTML = "Not Matched"
          document.getElementById('lbl_err').style.color = 'red';
          document.getElementById('resetpass').disabled = true;
      }
  }
}




logoutHandler =(e) =>{
  var user_id = localStorage.getItem("user")
  var sha256 = require('js-sha256');
  var userid_enc = sha256(user_id);

  var active_session = {
    start_time : Common_function.ServerDateTime(localStorage.getItem("cache focus date")),
    end_time: Common_function.ServerDateTime(new Date())
  }
   console.log(active_session ,"logout time detail")
  var device_detail = [{
   event_id : 1,
  unique_id : userid_enc,
    os_version : osVersion,
    model_name: osName,
    value : active_session,user_id:user_id,
  
  }]
  
  const analytics_details = {
    data : device_detail , source : 2
  };

  
 JSON.stringify(analytics_details)

//  console.log("hello..", analytics_details)
        
  ApiUrlCall.send_analytics(analytics_details).then(response => {
     
    if (response.status === 200) {
      return

    }
  }
  )
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("username");
  localStorage.setItem('cache focus date','')


  gl_requestcode="";
  gl_requesttype="";
  gl_orderno="";
  gl_list = [];
  gl_req_data = []
  timercounter = 0;
  setTimeout(function(){
    window.location.reload()
  }, 1000);
    
}

showPassword = (e) => {
  var inputs = document.getElementsByClassName('mpassword');
  Array.prototype.forEach.call(inputs, function(x) {
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  })
}





createServerDateTime = (date, time) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  if(month <= 9){
    month = '0'+month
  }
  if(day <= 9){
    day = '0'+day
  }
  var datetime = year + "-" + month + "-" + day + " " + time + ":" + "00"
  return datetime
}



showCollectionData = (e) =>{
  const {startDate,sendStartTime} = this.state
  
    var start_date_time = this.createServerDateTime(startDate,sendStartTime)
    

  
    var data = JSON.stringify({"date" : start_date_time})
   

    $.ajax({
      url: this.state.host+'/v1/mpgps/tractorjunctionDashboard/show_collection_team/',
      dataType: 'json',
      type:'post',
      data:data,
      beforeSend: function(request) {
          request.setRequestHeader("authorization", localStorage.getItem("token"));
          request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"username":localStorage.getItem("username")}));
          $("#loading-image").show();
          $(".loading-icon").show();
        },
      success: function(data) {
     //   console.log('collection Team data..',data.data);
        $("#loading-image").hide();
        $(".loading-icon").hide();
        this.setState({
          tableData :data.data
        },() =>{
          if(this.state.tableData.length == 0){
              alert("No data found.")
          }
        })
          
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.url, status, err.toString());
        //console.log('fail');
     //   alert("Internal Server Error");
        $("#loading-image").hide();
        $(".loading-icon").hide();
        if(err.toString() == "Forbidden"){
         //this.logoutHandler()
        }
      }.bind(this)
    });


}


////////////////////////////////////////////////////////

handleMobileNumberChange = (e) => {
  //this.setState({ mobileNumber: e.target.value });
  //console.log(e.target.value,"alfa")
  const inputValue = e.target.value;
    // Use a regular expression to check if the input contains only numbers
    const isNumeric = /^[0-9]+$/.test(inputValue);
    if(inputValue == ''){
      this.setState({
        mobileNumber: inputValue,
        errorMessage: '',
      });
    }
    else if (!isNumeric) {
      this.setState({
        mobileNumber: inputValue,
        errorMessage: 'Please enter only numbers in the mobile number field.',
      });
    }
    else {
      this.setState({
        mobileNumber: inputValue,
        errorMessage: '',
      });
    }
};

handleMemberNameChange = (e) => {
  this.setState({ member_name: e.target.value });
};

handleAddMobileNumber = () => {
  if (!this.state.member_name ) {
    this.setState({ errorMessage: 'Please enter Collection Team Member Name.' });
  }
  else if(!this.state.mobileNumber){
    this.setState({ errorMessage: 'Please enter Collection Team Member Mobile Number.' });
  } 
  else {
  this.setState({ errorMessage: '' });

  const { mobileNumber,member_name } = this.state;
  let reqdata = {}

  if (mobileNumber.trim() !== '') {
    // Process the mobile number, maybe add it to a list or perform some action
    {
      reqdata['mobile_number']= mobileNumber
      reqdata['member_name']=member_name
      $("#loading-image").show();
      $(".loading-icon").show();
      let formdata = reqdata
      //console.log(formdata,"formdata")
      const forgeturl = this.state.host+'/v1/mpgps/tractorjunctionDashboard/add_collection_team/'
      axios.post(forgeturl, formdata, {
        headers: {
          'Authorization': localStorage.getItem('token'),
        },
      })
        .then(response => {
          $("#loading-image").hide();
          $(".loading-icon").hide();
          //window.$("#mdsharelocation").modal('show')
          if (response.data.is_success) {
            //console.log(response.data,"mrunmayee",response.data.flag_create)
            //document.getElementById('resetpass').disabled = true;
            if (response.data.flag_create == '1'){    
              //console.log("response.data")    
              this.setState({ mobileNumber: '' });
              this.setState({ member_name: '' });
              toast.success('Collection Team Member Already Exists!',{
                className: 'custom-toast1',
              })
            }
            else if(response.data.flag_create == '2')
            {
              this.setState({ mobileNumber: '' });
              this.setState({ member_name: '' });
              toast.success('Collection Team Member Added successfully!');
              this.showCollectionData()
            }

          } else {
            toast.success('Error While Adding Member!',{
              className: 'custom-toast',
            });
          }
        })
        .catch(error => {
          console.log(error)  
          $("#loading-image").hide();
          $(".loading-icon").hide();      
          

        })
      }
    
    //console.log(`Adding mobile number: ${mobileNumber}`);
    //console.log(`Adding member name: ${member_name}`);
  }
}
};

handleDeleteMobileNumber = (mobileNumber) => {
  // Implement your logic here to delete the mobile number
  // You can remove the mobile number from your data or make an API call to delete it
  this.setState({ clickedMobileNumber: mobileNumber });
  //console.log(`Deleting mobile number: ${mobileNumber}`);
      let reqdata = {}
      reqdata['mobile_number']= mobileNumber
      //reqdata['member_name']=member_name
      $("#loading-image").show();
      $(".loading-icon").show();
      let formdata = reqdata
      //console.log(formdata,"formdata")
      const forgeturl = this.state.host+'/v1/mpgps/tractorjunctionDashboard/delete_collection_team/'
      axios.post(forgeturl, formdata, {
        headers: {
          'Authorization': localStorage.getItem('token'),
        },
      })
        .then(response => {
          $("#loading-image").hide();
          $(".loading-icon").hide();
          //window.$("#mdsharelocation").modal('show')
          if (response.data.flag_create  == '1') {
            //console.log(response.data,"mrunmayee",response.data.flag_create)
            //document.getElementById('resetpass').disabled = true;
  
              this.setState({ clickedMobileNumber: '' });
              toast.success('Collection Team Member Deleted successfully!');
              this.showCollectionData()
       

          } else {
            toast.success('Error While Deleting Member!');
          }
        })
        .catch(error => {
          console.log(error)  
          $("#loading-image").hide();
          $(".loading-icon").hide();      
          

        })

  // Optionally, you can show a success message here
};

/////////////////////////////////////////////////////////

render(){
 
  if(this.state.loggedIn === false){
      return <Redirect to="/" />
    }

  const headers = [
    { label: "Sr No.", key: "sr_no" },
    { label: "Collection Team Name", key: "member_name" },
    { label: "Mobile Number", key: "mobile_number" },
  ];

  const columns=[
  {
    Header: <Tooltip title="Sr No.">Sr No.</Tooltip>,
    accessor: "sr_no",
    style:{
      textAlign:"center"
    },
    Cell: (row) => { return <div>{row.index+1}</div>},
    width:100,
    minWidth:100,
    maxWidth:100
  },
  {
    Header: <Tooltip title="Collection Team Member">Collection Team Member</Tooltip>,
    accessor: "member_name",
    style:{
      textAlign:"center"
    },
    Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>},
    width:500,
    minWidth:500,
    maxWidth:500,
  },
  {
    Header: <Tooltip title="Mobile Number">Mobile Number</Tooltip>,
    accessor: "mobile_number",
    style:{
      textAlign:"center"
    },
    Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>},
    width:500,
    minWidth:500,
    maxWidth:500,
  }, 
  {
    Header: <Tooltip title="Delete Mobile Number">Actions</Tooltip>,
    accessor: "mobile_number", // Use a unique identifier for accessor
    style:{
      textAlign:"center"
    },
    Cell: (row) => (
      <span
      className="delete-icon"
      onClick={() => this.handleDeleteMobileNumber(row.original.mobile_number)}
      style={{
        textAlign:"center",
        cursor: "pointer",
        color: "red",
        fontSize: "1.2rem", // Adjust the icon size
        marginRight: "5px", // Add space to the right of the icon
        color: this.state.clickedMobileNumber === row.original.mobile_number ? "darkred" : "red", // Change color when clicked
        transform: this.state.clickedMobileNumber === row.original.mobile_number ? "scale(1.2)" : "scale(1)",


      }}
    >
      <FaTrashAlt /> 
    </span>
    ),
    width: 100,
  },   
  
  ]



  return (
    
    <div>
      {/*header component start*/}
      <div className="navbar navbar-default">
        <div className="container-fluid pl-0">
              <div className="navbar-header">
                <a className="header-logo">
                <Link to="/lenderdashboard"><img src={matchpointlogo} alt="matchpointlogo" width="300" className="logo"/></Link>
                </a>
              </div>
          <div className="nav navbar-nav navbar-right">
          <div className=" list-inline mb-0">
          <div id="profile_menu" className="list-inline-item fs-21 fc-red text-capitalize border-right cursor-pointer usernamemenu "
          onMouseOver={(e) => {
            //$("#profile_menu").toggle()
          }}
          onMouseLeave={(e) => {
            //$("#profile_menu").toggle()
          }}
          >
            Welcome, {this.state.user1}<span className="color-orange">
            </span>
            {/* <ul>
                <li><a href="#" data-toggle="modal" data-target="#resetPassword" >Change Password</a></li>
            </ul>  */}
             <ul>
          
          {this.state.userGroups[0] === '5' && this.state.userGroups[2] === '7' ? (
            <li>
               <Link to="/Api_integration" style={{ color: 'black', textDecoration: 'none',  }} className="custom-link" >
                 
                 <span style={{ textDecoration: 'none' }} >  Api Integration </span>

               </Link>
          </li> ) : null}
        
        </ul>

          </div>
          
          <div className="list-inline-item">
              <a onClick={e=>this.logoutHandler(e)} className="cursor-pointer">
                <img src={logout} alt="logout-logo" width="30"/>
              </a>
          </div>
          </div>
          </div>
      </div>
    </div>
    {/*header component end*/}

      {/* Range component start*/}
      <div className="container-fluid pt-35 red1">
          <div className="gray-shadow" style={{ marginLeft: "20px", width: 'fit-content' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
              type="text"
              placeholder="Enter Collection Team Member Name"
              value={this.state.member_name}
              onChange={this.handleMemberNameChange}
              //style={{ marginRight: '20px' }}
              style={{
                borderRadius: "10px", // Add curved corners
                padding: "8px", // Adjust padding as needed
                border: "1px solid #ccc", // Add a border for visual separation
                outline: "none", // Remove the default focus outline
                marginRight : '20px'
                // ... other styles ...
              }}
            />
            <input
              type="text"
              placeholder="Enter mobile number"
              value={this.state.mobileNumber}
              onChange={this.handleMobileNumberChange}
              //style={{ marginRight: '20px' }}
              style={{
                borderRadius: "10px", // Add curved corners
                padding: "8px", // Adjust padding as needed
                border: "1px solid #ccc", // Add a border for visual separation
                outline: "none", // Remove the default focus outline
                marginRight : '20px'
                // ... other styles ...
              }}
            />
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.handleAddMobileNumber}
            >
              Add Member
            </button>
          </div>
          </div>
          {/* Display the error message */}
          {this.state.errorMessage && (
            <p style={{ color: 'red' }}>{this.state.errorMessage}</p>
          )}
        <br/>
        </div>
        {/*Range component end */}

        {/* Dashboardtable component start */}
          <div className="red2">
            <div className="plr-20">
              <ReactTable columns={columns}
                  getTdProps={this.onRowClick}
                  ref={r => (this.reactTable = r)}
                  data={this.state.tableData}
                  showPagination={true}
                  pageSizeOptions = {[1000,2000]}
                  defaultPageSize={1000}
                  style={{
                    maxHeight: "630px" // This will force the table body to overflow and scroll, since there is not enough room
                  }}>
                </ReactTable>
              <div className="loading-icon" id="myDiv">
                <img id="loading-image" / >
              </div>
            </div>
          </div>
        {/* Dashboardtable component end */}

        {/* Reset Password model window start here */}
        <div class="modal fade" id="resetPassword" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close closebutton" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"><i class="fa fa-times"></i></span></button>
                <h4 class="modal-title">Change Your Account Password</h4>
              </div>
              <form href="" method="post" >
                <div class="modal-body">
                  <div class="form-group">
                    {/* <label for="accountEmail">Account Email</label> */}
                    <span class="help-block">Old Password.</span>
                    <input  id="old_password" type="password" onChange={this.onChange} class="form-control mpassword" required="required" name="old_password" />
                    <span  class="help-block">New Password.</span>
                    <input id="new_password" type="password" minlength="8" onChange={this.onChange} class="form-control mpassword" required="required" name="new_password" />
                    <span class="help-block ">Confirm Password.</span>
                    <input id="confirm_password" type="password" minlength="8" onChange={this.onChange} class="form-control mpassword" required="required" name="confirm_password" />
                  </div>
                  <input type="checkbox" onChange={this.showPassword} /> Show Password
                  <br/>
                  <label for="error-msg" id="lbl_err"></label>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-default btn-sm btn-icon" data-dismiss="modal">Cancel</button>
                  <button type="input" id="resetpass" onClick={this.postData} name="submit" value="resetPass" class="btn btn-success btn-sm btn-icon"> Change Password</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      {/* Reset Password model window end here */}
      <ToastContainer />                   
    </div>
        )
    }
}
export default CollectionTeam
